/***
 *** Types local to this repository (front end code)
 ***/

import {
  AddressType,
  GenderType,
  Maybe,
  MemberGender,
  MemberType,
} from "./schema";

/**
 * To keep TextFields clear an empty string is needed. TextField will throw an
 * error if `null` is passed as a value.
 */
export type FieldNumber = number | string;

/**
 * Like `T & U`, but using the value types from `U` where their properties
 * overlap.
 */
export type Overwrite<T, U> = Omit<T, keyof U> & U;

/**
 * Constructs a `Type` excluding `null`
 */
export type ExcludeNull<T> = { [P in keyof T]: NonNullable<T[P]> };

/**
 * Constructs a `Type` excluding `null` or `undefined`
 */
export type ExcludeNil<T> = { [P in keyof T]-?: NonNullable<T[P]> };

/**
 * Constructs a `Type` excluding `null` and with all properties of `Type` set to
 * optional
 */
export type ExcludeNullPartial<T> = { [P in keyof T]?: NonNullable<T[P]> };

export type UnknownObject<TKey extends string | number | symbol = string> =
  Record<TKey, unknown>;

export type EmptyObject<TKey extends string | number | symbol = string> =
  Record<TKey, never>;

export enum FilterOrder {
  Ascending = "asc",
  Descending = "desc",
}

export type $AntFilter = (string | number | boolean)[] | null;

export interface VerifyDetails {
  content: {
    data: {
      id: string;
      updated_at: string;
      client_ref: string;
      share_url: string;
    };
  };
}

/** An Enumeration */
export enum ElectronicVoucherCancellationResultCode {
  RequestReceived = "201",
  RequestAlreadySent = "202",
  UuidNotFound = "203",
  UuidNotApplicableForCancellation = "204",
  UuidDoesNotExist = "205",
  MalformedXml = "301",
  MalformedStamp = "302",
  StampDoesNotCorrespondToIssuer = "303",
  CertificateHasBeenRevoked = "304",
  InvalidCertificate = "305",
  InvalidUseOfCertificate = "310",
}

export type $MemberName = Partial<
  Pick<MemberType, "firstName" | "firstLastName" | "secondLastName">
>;

export type $BasicAddress = Pick<
  AddressType,
  | "address1"
  | "address2"
  | "neighborhood"
  | "municipality"
  | "stateName"
  | "zipcode"
  | "city"
>;

export const $SpeiInstitutionTypes = {
  37006: "BANCOMEXT",
  37009: "BANOBRAS",
  37019: "BANJERCITO",
  37135: "NAFIN",
  37166: "BANSEFI",
  37168: "HIPOTECARIA_FED",
  40002: "BANAMEX",
  40012: "BBVA_BANCOMER",
  40014: "SANTANDER",
  40021: "HSBC",
  40030: "BAJIO",
  40036: "INBURSA",
  40037: "INTERACCIONES",
  40042: "MIFEL",
  40044: "SCOTIABANK",
  40058: "BANREGIO",
  40059: "INVEX",
  40060: "BANSI",
  40062: "AFIRME",
  40072: "BANORTE_IXE",
  40102: "ACCENDO_BANCO",
  40103: "AMERICAN_EXPRES",
  40106: "BANK_OF_AMERICA",
  40108: "MUFG",
  40110: "JP_MORGAN",
  40112: "BMONEX",
  40113: "VE_POR_MAS",
  40124: "DEUTSCHE",
  40126: "CREDIT_SUISSE",
  40127: "AZTECA",
  40128: "AUTOFIN",
  40129: "BARCLAYS",
  40130: "COMPARTAMOS",
  40131: "BANCO_FAMSA",
  40132: "MULTIVA_BANCO",
  40133: "ACTINVER",
  40136: "INTERCAM_BANCO",
  40137: "BANCOPPEL",
  40138: "ABC_CAPITAL",
  40140: "CONSUBANCO",
  40141: "VOLKSWAGEN",
  40143: "CIBANCO",
  40145: "BBASE",
  40147: "BANKAOOL",
  40148: "PAGATODO",
  40150: "INMOBILIARIO",
  40151: "DONDE",
  40152: "BANCREA",
  40154: "BANCO_FINTERRA",
  40155: "ICBC",
  40156: "SABADELL",
  40158: "MIZUHO_BANK",
  40160: "BANCO_S3",
  90600: "MONEXCB",
  90601: "GBM",
  90602: "MASARI",
  90605: "VALUE",
  90606: "ESTRUCTURADORES",
  90608: "VECTOR",
  90613: "MULTIVA_CBOLSA",
  90616: "FINAMEX",
  90617: "VALMEX",
  90620: "PROFUTURO",
  90621: "CB_ACTINVER",
  90623: "SKANDIA",
  90627: "ZURICH",
  90628: "ZURICHVI",
  90630: "CB_INTERCAM",
  90631: "CI_BOLSA",
  90634: "FINCOMUN",
  90636: "HDI_SEGUROS",
  90637: "ORDER",
  90638: "AKALA",
  90642: "REFORMA",
  90646: "STP",
  90648: "EVERCORE",
  90649: "OSKNDIA",
  90652: "ASEA",
  90653: "KUSPIT",
  90655: "SOFIEXPRESS",
  90656: "UNAGRA",
  90659: "ASP_INTEGRA_OPC",
  90670: "LIBERTAD",
  90677: "CAJA_POP_MEXICA",
  90678: "SURA",
  90679: "FND",
  90680: "CRISTOBAL_COLON",
  90683: "CAJA_TELEFONIST",
  90684: "TRANSFER",
  90685: "FONDO_FIRA",
  90686: "INVERCAP",
  90689: "FOMPED",
  90901: "CLS",
  90902: "INDEVAL",
};

export const $SatProductServiceKeyTypes = {
  85121600: "Otras especialidades",
  85121601: "Ginecología",
  85121602: "Nefrología",
  85121603: "Cardiología",
  85121604: "Neumología",
  85121605: "Gastroenterología",
  85121606: "Geriatría",
  85121607: "Psiquiatría",
  85121608: "Psicología",
  85121609: "Cirugía",
  85121610: "Oftalmología",
  85121611: "Dermatología",
  85121612: "Ortopedia",
  85121613: "Pediatría",
  85121614: "Neurología",
};

export type $SpeiInstitutionTypesKeys = keyof typeof $SpeiInstitutionTypes;

export type $SatProductServiceKeyTypesKeys =
  keyof typeof $SatProductServiceKeyTypes;

export interface $PaymentGatewaySourceReference {
  bank?: $SpeiInstitutionTypesKeys;
  clabe?: string;
  paymentType?: keyof typeof $PaymentType;
}

export const $GenderTypeMap = {
  [GenderType.Female]: MemberGender.F,
  [GenderType.Male]: MemberGender.M,
  [GenderType.Unknown]: MemberGender.U,
};

export const $MemberGenderMap = {
  [MemberGender.F]: GenderType.Female,
  [MemberGender.M]: GenderType.Male,
  [MemberGender.U]: GenderType.Unknown,
};

export const $PaymentType = {
  1: "PEER_TO_PEER",
  3: "VOSTRO_PEER_TO_PEER",
};

export interface $CommentType {
  author?: Maybe<string>;
  content?: string;
  datetime?: string;
}
