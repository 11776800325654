import * as Sentry from "@sentry/react";
import { Spin } from "antd";
import routes from "config/routes";
import { filterNil } from "library/utils/array";
import { observer } from "mobx-react";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { loginState } from "screens/Login";
import AppLayout from "../AppLayout";
import { Loader } from "../AppLayout/AppLayout.css";

const SentryRoute = Sentry.withSentryRouting(Route);

interface RoutesHandlerProps {
  loginState: typeof loginState;
}

/**
 *Checking route groups allowed (or undefined) per view vs logged user groups (or undefined)
 */
const containsGroup = (routeGroups?: string[], userGroup?: string[]) => {
  if (routeGroups && userGroup) {
    return Boolean(routeGroups.find(group => userGroup.includes(group)));
  }
  return !Boolean(routeGroups);
};

const RoutesHandler: React.FC<RoutesHandlerProps> = observer(
  ({ loginState }) => {
    const loader = (
      <Loader>
        <Spin />
      </Loader>
    );

    return (
      <AppLayout visible={loginState.isLoggedIn}>
        <Suspense fallback={loader}>
          <Switch>
            {routes.map(
              ({
                component,
                exact,
                isPublic,
                path,
                translationTab,
                groups,
              }) => (
                <SentryRoute
                  key={path}
                  exact={exact}
                  path={path}
                  render={props => {
                    if (translationTab) {
                      document.title = translationTab;
                    }

                    if (loginState.sessionIsKilled) {
                      loginState.setSessionIsKilled(false);
                      return <Redirect to="/" />;
                    }

                    if (loginState.isLoggedIn && path === "/") {
                      return <Redirect to="/inicio" />;
                    }

                    if (
                      (isPublic || loginState.isLoggedIn) &&
                      containsGroup(
                        groups,
                        filterNil(loginState.userGroups || []),
                      )
                    ) {
                      // ! Disabling rule because of libraries (React - React
                      // Router) TS types incompatibility:
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      return React.createElement(component, props as any);
                    }

                    return <Redirect to="/401" push />;
                  }}
                />
              ),
            )}
            <Route
              path="*"
              component={lazy(() => import("screens/NotFound"))}
            />
          </Switch>
        </Suspense>
      </AppLayout>
    );
  },
);

export default RoutesHandler;
