export interface PrettyJoinOpts {
  separator?: string;
  trim?: boolean;
  whitespace?: boolean;
}

type PrettyJoin = (value: Array<unknown>, opts?: PrettyJoinOpts) => string;

export interface TrimTextOpts {
  count?: number;
  suffix?: string;
}

/**
 * @see {@link https://stackoverflow.com/a/37511463/3841699} source
 */
export const rmAccents = (value: string) =>
  value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const toUppercaseUnaccented = (value: string) =>
  rmAccents(value).toUpperCase();

export const rmWhitespace = (val: string): string => {
  return val.replace(/\s+/g, " ");
};

export const prettyJoin: PrettyJoin = (values, opts) => {
  const { separator, whitespace, trim }: PrettyJoinOpts = {
    separator: " ",
    whitespace: false,
    trim: true,
    ...opts,
  };
  let output = values
    .filter(
      value =>
        value && (typeof value === "string" || typeof value === "number"),
    )
    .join(separator);
  if (!whitespace) output = rmWhitespace(output);
  return trim ? output.trim() : output;
};

export const truncate = (value: string, opts?: TrimTextOpts) => {
  const { count, suffix } = {
    count: 24,
    suffix: "...",
    ...opts,
  };
  const sliced = value.slice(0, count);
  return sliced.length === value.length ? value : sliced + suffix;
};

export const URL_REGEX = new RegExp(
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
);
export const isUrl = (value: string) => {
  return URL_REGEX.test(value);
};
