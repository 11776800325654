import client from "config/client";
import gql from "graphql-tag";
import {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  SelfQuery,
  SelfQueryVariables,
  TokenAuthMutation,
  TokenAuthMutationVariables,
} from "types/schema";

const TOKEN_AUTH = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
      payload
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const SELF = gql`
  query Self($id: Int) {
    user(id: $id) {
      id
      email
      hasConfirmedDevice
      isDoctor
      # Don't remove, requesting this flag will check if the user logging in has
      # staff permission
      isStaff
      groups
      multifactorVerificationRequired
    }
  }
`;

export const tokenAuth = async (vars: TokenAuthMutationVariables) => {
  const response = await client.mutate<
    TokenAuthMutation,
    TokenAuthMutationVariables
  >({
    mutation: TOKEN_AUTH,
    variables: vars,
  });
  return response?.data?.tokenAuth;
};

export const tokenRefresh = async (vars: RefreshTokenMutationVariables) => {
  const response = await client.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: REFRESH_TOKEN,
    variables: vars,
  });
  return response?.data?.refreshToken;
};

export const selfQuery = async () => {
  const response = await client.query<SelfQuery, SelfQueryVariables>({
    query: SELF,
  });
  return response.data?.user;
};
