import { isApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import isArray from "lodash/isArray";
import upperFirst from "lodash/upperFirst";
import translations from "res/translations";

type GetErrorMsgFn = (error: Error, index?: number) => string;

const getErrorFromArray = (collection: Array<GraphQLError>, index: number) => {
  const item = collection[index];
  if (item && item.message) {
    return upperFirst(item.message);
  }
};

export const getErrorMsg: GetErrorMsgFn = (error, index = 0) => {
  const { empty } = translations.errors;

  if (isApolloError(error)) {
    const { graphQLErrors, networkError } = error;
    if (networkError) {
      return `${networkError.message}`;
    }
    if (isArray(graphQLErrors) && graphQLErrors.length) {
      return getErrorFromArray(graphQLErrors, index) || empty;
    }
  }

  return error.message || empty;
};

export const getErrorMsgV2 = (error: Error) => {
  const { empty } = translations.errors;

  if (isApolloError(error)) {
    const { graphQLErrors, networkError } = error;
    if (networkError) {
      return {
        title: translations.errors.networkError,
        content: networkError.message,
      };
    }

    if (isArray(graphQLErrors) && graphQLErrors.length) {
      return {
        title: "Error",
        content: getErrorFromArray(graphQLErrors, 0) || empty,
      };
    }
  }

  return {
    title: "Error",
    content: error.message || empty,
  };
};

export default getErrorMsg;
