import { Switch } from "antd";
import cache, { Theme } from "config/cache";
import themesConfig from "config/themes";
import { isNil } from "lodash";
import { useObserver } from "mobx-react";
import React, { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { MoonIcon, SunIcon } from "res/icons";

const iconsStyle = {
  color: "yellow",
};

const ThemeSwitch: React.FC = () => {
  const { switcher, themes, currentTheme, status } = useThemeSwitcher();

  const updateTheme = (checked: boolean) => {
    const theme = checked ? themes.dark : themes.light;
    switcher({ theme });
    cache.settings.set("theme", theme as Theme);
  };

  useEffect(() => {
    const userTheme = cache.settings.item.theme;
    const prefersDark = themesConfig.prefersDark;

    if (userTheme && userTheme !== currentTheme) {
      // Set local-stored theme
      switcher({ theme: themes[userTheme] });
    } else if (prefersDark && isNil(userTheme)) {
      // Set system preferred theme
      switcher({ theme: themes.dark });
      cache.settings.set("theme", Theme.Dark);
    }
  }, [currentTheme, switcher, themes]);

  return useObserver(() => (
    <Switch
      checked={currentTheme === Theme.Dark}
      onChange={updateTheme}
      checkedChildren={<MoonIcon style={iconsStyle} />}
      unCheckedChildren={<SunIcon style={iconsStyle} />}
      loading={status === "loading"}
      disabled={status === "loading"}
    />
  ));
};

export default ThemeSwitch;
