import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ConfigProvider } from "antd";
import esEs from "antd/es/locale/es_ES";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import moment from "moment";
import React from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from "react-dom/client";
import Favicon from "react-favicon";
import App, { history } from "./App";
import themes from "./config/themes";
import executeSprigPixel from "library/utils/sprig";

executeSprigPixel();

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.5,
  environment: import.meta.env.VITE_APP_ENV,
  enabled: import.meta.env.VITE_SENTRY_ENABLED === "true",
  beforeBreadcrumb: excludeGraphQLFetch,
});

/**
 * Use ISO8601 that reflects the moment's UTC offset
 * @see {@link https://momentjs.com/docs/#/displaying/as-json/} Source
 */
moment.fn.toJSON = function () {
  return this.format();
};

/**
 * Parse two digit strings to our quotation needs:
 * < 65 years from now is 2000
 * > 65 years from now is 1900
 * @see {@link https://momentjs.com/docs/#/parsing/string-format/}
 */
moment.parseTwoDigitYear = function (yearString) {
  const year = parseInt(yearString);
  const minYear = moment().subtract(65, "years").year().toString().slice(2, 4);
  return year < parseInt(minYear) ? 2000 + year : 1900 + year;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <ConfigProvider locale={esEs}>
        <ThemeSwitcherProvider
          defaultTheme={themes.defaultTheme}
          insertionPoint={themes.insertionPoint}
          themeMap={themes.list}
        >
          <Favicon url={themes.favicon} />
          <App />
        </ThemeSwitcherProvider>
      </ConfigProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
