import { ApolloError, FetchResult } from "@apollo/client";
import { TablePaginationConfig } from "antd/lib/table";
import { SorterResult, SortOrder } from "antd/lib/table/interface";
import client from "config/client";
import { rmNull } from "library/utils/fields";
import { observable, runInAction } from "mobx";
import translations from "res/translations";
import { $AntFilter, FilterOrder } from "types/index";
import {
  HealthPlanApplicationColumnFragment,
  HealthPlanApplicationListDocument,
  HealthPlanApplicationListQuery,
  HealthPlanApplicationListQueryVariables,
  HealthPlanApplicationState,
  PreviousProductType,
  ProductFamilies,
} from "types/schema";
import { ActiveFiltersItem, ActiveFiltersProps } from "../ActiveFilters";

export enum OpsOrMedicalAtenttionFilterOptions {
  ReviewNeededOpsFilter = "reviewNeededOpsFilter",
  MoreInfoNeededOpsFilter = "moreInfoNeededOpsFilter",
  ReviewNeededMedicalFilter = "reviewNeededMedicalFilter",
  MoreInfoNeededMedicalFilter = "moreInfoNeededMedicalFilter",
}

interface HealthPlanApplicationListStore<T> {
  pagination: TablePaginationConfig;
  sort?: SortOrder;
  memberNameFilter?: $AntFilter;
  userIdFilter?: number;
  stateFilter?: $AntFilter;
  opsOrMedicalAtenttionFilter?: $AntFilter;
  productFamilyFilter?: $AntFilter;
  previousProductTypeFilter?: $AntFilter;
  sortVar: FilterOrder;
  stateFilterVar?: HealthPlanApplicationState[];
  productFamilyFilterVar?: ProductFamilies | null;
  previousProductTypeFilterVar?: PreviousProductType | null;
  memberNameFilterVar?: string;
  translatedState?: string[];
  activeFilters: ActiveFiltersItem[];
  updateSort: (value: SorterResult<T> | SorterResult<T>[]) => void;
  clearFilters: Required<ActiveFiltersProps>["onClear"];
  opsOrMedicalAtenttionFilterVars: Pick<
    HealthPlanApplicationListQueryVariables,
    | "reviewNeededOps"
    | "moreInfoNeededOps"
    | "reviewNeededMedical"
    | "moreInfoNeededMedical"
  >;
  queryError?: ApolloError;
  queryLoading?: boolean;
  queryResults?: HealthPlanApplicationColumnFragment[];
  queryVars?: HealthPlanApplicationListQueryVariables;
  query: () => Promise<FetchResult<HealthPlanApplicationListQuery> | undefined>;
  setQueryVars: (value: HealthPlanApplicationListQueryVariables) => void;
}

export const healthPlanApplicationStates = [
  HealthPlanApplicationState.Draft,
  HealthPlanApplicationState.ReviewNeeded,
  HealthPlanApplicationState.MoreInfoNeeded,
  HealthPlanApplicationState.PaymentFailed,
  HealthPlanApplicationState.ApprovedForPayment,
  HealthPlanApplicationState.Rejected,
  HealthPlanApplicationState.Subscribed,
  HealthPlanApplicationState.Cancelled,
  HealthPlanApplicationState.Closed,
  HealthPlanApplicationState.WaitingSubscriber,
  HealthPlanApplicationState.ToBeRenewed,
];

const store = observable<
  HealthPlanApplicationListStore<HealthPlanApplicationColumnFragment>
>({
  pagination: {
    total: 0,
    current: 1,
    pageSize: 50,
    pageSizeOptions: ["15", "35", "50"],
  },
  sort: "descend",
  memberNameFilter: null,
  stateFilter: healthPlanApplicationStates.filter(
    e => e !== HealthPlanApplicationState.Draft,
  ),
  opsOrMedicalAtenttionFilter: null,

  get sortVar() {
    return this.sort === "descend"
      ? FilterOrder.Descending
      : FilterOrder.Ascending;
  },
  get stateFilterVar() {
    return this.stateFilter;
  },
  get opsOrMedicalAtenttionFilterVars() {
    const value = this.opsOrMedicalAtenttionFilter?.[0];
    if (!value) return {};
    return {
      reviewNeededOps:
        value === OpsOrMedicalAtenttionFilterOptions.ReviewNeededOpsFilter,
      moreInfoNeededOps:
        value === OpsOrMedicalAtenttionFilterOptions.MoreInfoNeededOpsFilter,
      reviewNeededMedical:
        value === OpsOrMedicalAtenttionFilterOptions.ReviewNeededMedicalFilter,
      moreInfoNeededMedical:
        value ===
        OpsOrMedicalAtenttionFilterOptions.MoreInfoNeededMedicalFilter,
    };
  },
  get translatedState() {
    return this.stateFilterVar?.map(
      (e: HealthPlanApplicationState) =>
        translations.healthPlanApplicationState[e],
    );
  },
  get memberNameFilterVar() {
    return this.memberNameFilter?.[0];
  },
  get productFamilyFilterVar() {
    return this.productFamilyFilter?.[0] as ProductFamilies;
  },
  get previousProductTypeFilterVar() {
    return this.previousProductTypeFilter?.[0] as PreviousProductType;
  },
  get activeFilters() {
    const filters: ActiveFiltersItem[] = [
      {
        id: "memberName",
        label: "Contratante",
        value: this.memberNameFilterVar,
      },
      {
        id: "state",
        label: "Estado",
        value: this.translatedState?.toString(),
      },
      {
        id: "productFamily",
        label: "Producto",
        value: this.productFamilyFilterVar as ProductFamilies,
      },
      {
        id: "previousProductType",
        label: "Producto Anterior",
        value: this.previousProductTypeFilterVar as PreviousProductType,
      },
      {
        id: "review",
        label: "Revisión",
        value: this.opsOrMedicalAtenttionFilter?.toString(),
      },
    ];
    return filters;
  },
  clearFilters(filterId, clearAll) {
    if (filterId === "memberName" || clearAll) this.memberNameFilter = null;
    if (filterId === "state" || clearAll) this.stateFilter = null;
    if (filterId === "review" || clearAll)
      this.opsOrMedicalAtenttionFilter = null;
    if (filterId === "productFamily" || clearAll)
      this.productFamilyFilter = null;
    if (filterId === "previousProductType" || clearAll)
      this.previousProductTypeFilter = null;
  },
  updateSort(sorter) {
    if (Array.isArray(sorter)) {
      const _sorter = sorter.find(value => value.column?.dataIndex === "id");
      if (_sorter) this.sort = _sorter.order;
    } else {
      this.sort = sorter.order;
    }
  },

  queryError: undefined,
  queryLoading: false,
  queryResults: undefined,
  queryVars: undefined,

  setQueryVars(value) {
    this.queryVars = {
      ...this.queryVars,
      ...value,
    };
  },

  async query() {
    const { current, pageSize } = this.pagination;
    if (!current || !pageSize) return;

    runInAction(() => {
      this.queryLoading = true;
      this.queryError = undefined;
    });

    try {
      const response = await client.mutate<
        HealthPlanApplicationListQuery,
        HealthPlanApplicationListQueryVariables
      >({
        mutation: HealthPlanApplicationListDocument,
        variables: {
          memberName: this.memberNameFilterVar,
          userId: this.userIdFilter,
          state: this.stateFilterVar,
          productFamily: this.productFamilyFilterVar,
          previousProductType: this.previousProductTypeFilterVar,
          offset: (current - 1) * pageSize,
          count: pageSize,
          ...this.opsOrMedicalAtenttionFilterVars,
          ...this.queryVars,
        },
      });
      const data = response?.data?.healthPlanApplications;

      runInAction(() => {
        this.queryResults = rmNull(data?.results);
        this.pagination.total = data?.totalCount || 0;
      });

      return response;
    } catch (err) {
      runInAction(() => {
        if (err instanceof ApolloError) {
          this.queryError = err;
        }
      });
    } finally {
      runInAction(() => {
        this.queryLoading = false;
      });
    }
  },
});

export default store;
