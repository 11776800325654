import { Button as AntButton, Layout } from "antd";
import styled from "styled-components";

export const Header = styled(Layout.Header)`
  padding: 0;
`;

export const IconContainer = styled.div`
  float: left;
  padding: 0 24px;
  line-height: 64px;
  cursor: pointer;

  .anticon {
    color: #989898;
    font-size: 18px;
  }
`;

export const RightActions = styled.div`
  float: right;
  padding: 0 24px;
`;

export const Button = styled(AntButton)`
  margin-left: 8px;
`;
