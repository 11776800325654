import useEventListener from "library/hooks/useEventListener";
import { prettyJoin } from "library/utils/string";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import AppMenu from "../AppMenu";
import AppToolbar from "../AppToolbar";
import RenderIf from "../RenderIf";
import { Content, ContentLayout, Layout } from "./AppLayout.css";
import AppLayoutState from "./AppLayoutState";

const state = new AppLayoutState();

type AppLayoutProps = React.PropsWithChildren<{
  visible: boolean;
}>;

const AppLayout: React.FC<AppLayoutProps> = observer(
  ({ children, visible = true }) => {
    useEffect(() => {
      state.isMobileMenu = state.isMobileBreakpoint;
    }, []);

    useEventListener("resize", () => {
      state.isMobileMenu = state.isMobileBreakpoint;
    });

    const onAppMenuLinkClick = () => {
      // The menu should only close after a link is clicked in *mobile*
      if (state.isMobileMenu) state.isMenuVisible = false;
    };

    return (
      <Layout>
        <RenderIf value={visible} fallback={() => <>{children}</>}>
          {(() => (
            <>
              <AppMenu
                visible={state.isMenuVisible}
                onClose={() => state.toggleIsMenuVisible()}
                wrapInDrawer={state.isMobileMenu}
                onLinkClick={onAppMenuLinkClick}
              />
              <ContentLayout
                className={prettyJoin([
                  state.isMobileMenu && "is-mobile-menu",
                  state.isMenuVisible && "is-menu-open",
                ])}
              >
                <AppToolbar
                  isMenuOpen={state.isMenuVisible}
                  onTriggerClick={() => state.toggleIsMenuVisible()}
                />
                <Content>
                  <Layout.Content>{children}</Layout.Content>
                </Content>
              </ContentLayout>
            </>
          ))()}
        </RenderIf>
      </Layout>
    );
  },
);

export default AppLayout;
