import Icon from "@ant-design/icons";
import React from "react";
import { IconProps } from "./IconTypes";

const MoonSvg = () => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 120 120">
      <path d="M34.5 112.108C53.7481 102.671 67 82.8831 67 60C67 37.1169 53.7481 17.3289 34.5 7.89179C42.196 4.11852 50.8506 2 60 2C92.0325 2 118 27.9675 118 60C118 92.0325 92.0325 118 60 118C50.8506 118 42.196 115.881 34.5 112.108Z" />
    </svg>
  );
};

export const MoonIcon = (props: IconProps) => (
  <Icon component={MoonSvg} {...props} />
);

export default MoonIcon;
