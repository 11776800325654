
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "MemberExclusionContentObjectType": [
      "HealthPlanAmendmentType",
      "HealthPlanApplicationItemType",
      "ProfilingDataType",
      "MedicalNoteType",
      "ServicesType",
      "OnDemandConsultType",
      "InPersonConsultType",
      "PharmacyOrderType",
      "MedicalFeeType",
      "HospitalServiceType",
      "OtherServiceType",
      "RehabilitationTherapyType",
      "LabStudiesType",
      "MedicinesType",
      "MedicalEquipmentType",
      "HomeNurseType",
      "ProstheticsAndMechanicalEquipmentType",
      "AmbulanceType",
      "ExternalConsultType",
      "ExternalVideoConsultType",
      "UrgencyType",
      "InPersonConsultByProviderType",
      "LimitedIncludedConsultType"
    ],
    "ContentObjectType": [
      "HealthPlanType",
      "OnDemandConsultTimeLogType",
      "HealthPlanAmendmentType",
      "ServicesType",
      "OnDemandConsultType",
      "InPersonConsultType",
      "PharmacyOrderType",
      "MedicalFeeType",
      "HospitalServiceType",
      "OtherServiceType",
      "RehabilitationTherapyType",
      "LabStudiesType",
      "MedicinesType",
      "MedicalEquipmentType",
      "HomeNurseType",
      "ProstheticsAndMechanicalEquipmentType",
      "AmbulanceType",
      "IncentiveType",
      "ExternalConsultType",
      "ExternalVideoConsultType",
      "UrgencyType",
      "InPersonConsultByProviderType",
      "SurgicalMaterialsType"
    ],
    "CareDirectoryType": [
      "DoctorProfileType",
      "ProviderBranchType"
    ],
    "CareProviderType": [
      "DoctorProfileType",
      "DoctorFriendType",
      "ProviderBranchType"
    ],
    "ConsultUnionType": [
      "OnDemandConsultType",
      "LimitedInPersonConsultType",
      "LimitedIncludedConsultType",
      "LimitedInPersonConsultByProviderType",
      "LimitedRehabilitationTherapyType",
      "LimitedChatConsultType"
    ],
    "AppLimitedConsultUnionType": [
      "AppLimitedOnDemandConsultType",
      "AppLimitedInPersonConsultType",
      "AppLimitedIncludedConsultType",
      "AppLimitedInPersonConsultByProviderType",
      "AppLimitedRehabilitationTherapyType",
      "AppLimitedChatConsultType"
    ]
  }
};
      export default result;
    