import React, { lazy } from "react";
import { GroupName } from "types/schema";

export interface Route {
  component: React.LazyExoticComponent<React.FC<Record<string, unknown>>>;
  exact?: boolean;
  isPublic?: boolean;
  path: string;
  translationTab?: string;
  groups?: string[];
}

/**
 * ! Remember to add new routes from most to less specific
 */
const routes: Route[] = [
  {
    component: lazy(() => import("screens/Login")),
    exact: true,
    isPublic: true,
    path: "/",
  },
  {
    component: lazy(() => import("screens/Welcome")),
    isPublic: false,
    path: "/inicio",
    translationTab: "Inicio",
  },
  {
    component: lazy(() => import("screens/Credential")),
    isPublic: false,
    path: "/users/:id",
    translationTab: "Credenciales",
  },
  {
    component: lazy(() => import("screens/CredentialList")),
    isPublic: false,
    path: "/users",
    translationTab: "Credenciales",
  },
  {
    component: lazy(() => import("screens/UpdateMedicalSpecialization")),
    isPublic: false,
    path: "/medical-specializations/update/:id",
    translationTab: "Especialidades",
  },
  {
    component: lazy(() => import("screens/CreateMedicalSpecialization")),
    isPublic: false,
    path: "/medical-specializations/create",
    translationTab: "Crear especialidad",
  },
  {
    component: lazy(() => import("screens/MedicalSpecializationList")),
    isPublic: false,
    path: "/medical-specializations",
    translationTab: "Editar especialidad",
  },
  {
    component: lazy(() => import("screens/Member/MemberService")),
    isPublic: false,
    path: "/services/detail/:id/:tab",
    translationTab: "Servicios/detalle",
  },
  {
    component: lazy(() => import("screens/Member/MemberService")),
    isPublic: false,
    path: "/services/detail/:id",
    translationTab: "Servicios/detalle",
  },
  {
    component: lazy(() => import("screens/MigrateMemberToBusiness")),
    isPublic: false,
    path: "/members/migrate-to-business/:memberId",
    translationTab: "Migrar soci@ a empresa",
  },
  {
    component: lazy(() => import("screens/Member/MemberInformationV2")),
    isPublic: false,
    path: "/members/:id",
    translationTab: "Soci@s",
  },
  {
    component: lazy(() => import("screens/Persons")),
    isPublic: false,
    path: "/members",
    translationTab: "Soci@s",
  },
  {
    component: lazy(() => import("screens/ProfilingDataMember")),
    isPublic: false,
    path: "/profiling-data-members/:id",
    translationTab: "Validaciones Colectivo",
  },
  {
    component: lazy(() => import("screens/ProfilingDataPersons")),
    isPublic: false,
    path: "/profiling-data-members",
    translationTab: "Validaciones Colectivo",
  },
  {
    component: lazy(() => import("screens/PermissionList")),
    isPublic: false,
    path: "/permissions",
    translationTab: "Usuarios",
  },
  {
    component: lazy(() => import("screens/IncentiveCodesList")),
    isPublic: false,
    path: "/incentive-codes",
    translationTab: "Usuarios",
  },
  {
    component: lazy(
      () => import("screens/EventV2/ProvisionalLetter/ProvisionalLetterScreen"),
    ),
    isPublic: false,
    path: "/events-v2/detail/:id/quote/:quoteId/provisional-letter",
    translationTab: "Eventos",
  },
  {
    component: lazy(() => import("screens/EventV2/EventQuoter/EditEventQuote")),
    isPublic: false,
    path: "/events-v2/detail/:id/quoter/edit/:quoteId",
    translationTab: "Eventos",
  },
  {
    component: lazy(
      () => import("screens/EventV2/EventQuoter/CreateEventQuote"),
    ),
    isPublic: false,
    path: "/events-v2/detail/:id/quoter",
    translationTab: "Eventos",
  },
  {
    component: lazy(() => import("screens/EventV2/CaseEventFinalLetter")),
    isPublic: false,
    path: "/events-v2/detail/:id/final-letter",
    translationTab: "Eventos",
  },
  {
    component: lazy(() => import("screens/EventV2/EventScreenV2")),
    isPublic: false,
    path: "/events-v2/detail/:id",
    translationTab: "Eventos",
  },
  {
    component: lazy(() => import("screens/EventsListV2")),
    isPublic: false,
    path: "/events-v2",
    translationTab: "Eventos",
  },
  {
    component: lazy(() => import("screens/Services")),
    isPublic: false,
    path: "/services/:id",
    translationTab: "Registro de Servicios",
  },
  {
    component: lazy(() => import("screens/ServicesList/ServicesTab")),
    isPublic: false,
    path: "/services",
    translationTab: "Registro de Servicios",
  },
  {
    component: lazy(() => import("screens/ClosedNotesList")),
    isPublic: false,
    path: "/consults-audit",
    translationTab: "Auditoría de Consultas",
  },
  {
    component: lazy(() => import("screens/IncompleteServices")),
    isPublic: false,
    path: "/incomplete-services",
    translationTab: "Completar Diagnosis",
  },
  {
    component: lazy(() => import("screens/PharmacyOrders/UpdateOrder")),
    isPublic: false,
    path: "/pharmacy-orders/item/:id",
    translationTab: "Orden de farmacia",
  },
  {
    component: lazy(() => import("screens/PharmacyOrders")),
    isPublic: false,
    path: "/pharmacy-orders/:tab",
    translationTab: "Orden de farmacia",
  },
  {
    component: lazy(() => import("screens/ApplicationItem")),
    isPublic: false,
    path: "/applications/item/:id",
    translationTab: "Aplicaciones/item",
  },
  {
    component: lazy(() => import("screens/ApplicationList")),
    isPublic: false,
    path: "/applications/member/:id",
    translationTab: "Aplicaciones/soci@",
  },
  {
    component: lazy(() => import("screens/Application")),
    isPublic: false,
    path: "/applications/:id",
    translationTab: "Aplicaciones/detalle",
  },
  {
    component: lazy(() => import("screens/ApplicationList")),
    isPublic: false,
    path: "/applications",
    translationTab: "Aplicaciones",
  },
  {
    component: lazy(() => import("screens/HuliEmrList")),
    isPublic: false,
    path: "/consults",
    translationTab: "Consultas",
  },
  {
    component: lazy(() => import("screens/Amendment")),
    isPublic: false,
    path: "/amendments/:id",
    translationTab: "Endoso/detalle",
  },
  {
    component: lazy(() => import("screens/AmendmentList")),
    isPublic: false,
    path: "/amendments",
    translationTab: "Endosos",
  },
  {
    component: lazy(() => import("screens/Event/EventTab")),
    isPublic: false,
    path: "/events/:id",
    translationTab: "Eventos",
  },
  {
    component: lazy(() => import("screens/CreateBusinessReissueProposal")),
    isPublic: false,
    path: "/businesses/:businessId/create-business-reissue-proposal",
    translationTab: "Crear Propuesta",
  },
  {
    component: lazy(
      () => import("screens/CreateBusinessMaternityReissueProposal"),
    ),
    isPublic: false,
    path: "/businesses/:businessId/create-maternity-reissue-proposal",
    translationTab: "Crear Propuesta",
  },
  {
    component: lazy(() => import("screens/BusinessMaternityReissueProposal")),
    isPublic: false,
    path: "/businesses/:businessId/maternity-reissue-proposal/:proposalId",
    translationTab: "Aceptar cambios",
  },
  {
    component: lazy(() => import("screens/CreateGroupConfigReissueProposal")),
    isPublic: false,
    path: "/businesses/:businessId/create-group-config-reissue-proposal",
    translationTab: "Crear Propuesta",
  },
  {
    component: lazy(() => import("screens/BusinessGroupConfigReissueProposal")),
    isPublic: false,
    path: "/businesses/:businessId/group-config-reissue-proposal/:groupConfigProposalId",
    translationTab: "Aceptar cambios",
  },
  {
    component: lazy(() => import("screens/BusinessRenewalProposal")),
    isPublic: false,
    path: "/businesses/:businessId/renewal-proposal/:proposalId",
    translationTab: "Renewal Proposal",
  },
  {
    component: lazy(() => import("screens/ReissueApplication")),
    isPublic: false,
    path: "/healthplans/:id/reissue",
    translationTab: "Planes/reemisión",
  },
  {
    component: lazy(() => import("screens/HealthPlan")),
    isPublic: false,
    path: "/healthplans/:id",
    translationTab: "Planes/detalle",
  },
  {
    component: lazy(() => import("screens/HealthPlanList")),
    isPublic: false,
    path: "/healthplans",
    translationTab: "Planes",
  },
  {
    component: lazy(() => import("screens/CreateLocation")),
    isPublic: false,
    path: "/locations/create",
    translationTab: "Zonas de cobertura/crear",
  },
  {
    component: lazy(() => import("screens/EditLocation")),
    isPublic: false,
    path: "/locations/edit/:id",
    translationTab: "Zonas de cobertura/editar",
  },
  {
    component: lazy(() => import("screens/EditBatchLocations")),
    isPublic: false,
    path: "/locations/edit-batch/:ids",
    translationTab: "Zonas de cobertura/editar",
  },

  {
    component: lazy(() => import("screens/Location")),
    isPublic: false,
    path: "/locations/:id",
    translationTab: "Zonas de cobertura/detalle",
  },

  {
    component: lazy(() => import("screens/LocationList")),
    isPublic: false,
    path: "/locations",
    translationTab: "Zonas de cobertura",
  },
  {
    component: lazy(
      () => import("screens/ProvidersBranchOffices/ProvidersBranchOfficesItem"),
    ),
    isPublic: false,
    path: "/providers/branch-offices/item/:id",
    translationTab: "Proveedores/Sucursales/item",
  },
  {
    component: lazy(() => import("screens/ProvidersBranchOffices")),
    isPublic: false,
    path: "/providers/branch-offices/:id",
    translationTab: "Proveedores/Sucursales",
  },
  {
    component: lazy(
      () =>
        import(
          "screens/ProvidersBranchOffices/ProvidersBranchOfficeItemCreate"
        ),
    ),
    isPublic: false,
    path: "/providers/office/create/:id",
    translationTab: "Proveedores/Sucursal/Creación",
  },
  {
    component: lazy(
      () =>
        import("screens/ProvidersBranchOffices/ProvidersBranchOfficeItemEdit"),
    ),
    isPublic: false,
    path: "/providers/office/edit/:id",
    translationTab: "Proveedores/Sucursal/Edición",
  },
  {
    component: lazy(() => import("screens/Providers/ProviderItem")),
    isPublic: false,
    path: "/providers/item/:id",
    translationTab: "Proveedores/item",
  },
  {
    component: lazy(() => import("screens/Providers/ProviderItemCreate")),
    isPublic: false,
    path: "/providers/create",
    translationTab: "Proveedores/Creación",
  },
  {
    component: lazy(() => import("screens/Providers/ProviderItemEdit")),
    isPublic: false,
    path: "/providers/edit/:id",
    translationTab: "Proveedores/Edición",
  },
  {
    component: lazy(() => import("screens/Providers")),
    isPublic: false,
    path: "/providers/:tab",
    translationTab: "Proveedores",
  },
  {
    component: lazy(() => import("screens/InvoiceList/InvoicesTab")),
    isPublic: false,
    path: "/invoices/:tab/member/:id",
    translationTab: "Pagos y Cobros/soci@",
  },
  {
    component: lazy(() => import("screens/Invoice")),
    isPublic: false,
    path: "/invoices/:tab/:id",
    translationTab: "Pagos y Cobros/detalle",
  },
  {
    component: lazy(() => import("screens/InvoiceList/InvoicesTab")),
    isPublic: false,
    path: "/invoices/:tab",
    translationTab: "Recibos",
  },
  {
    component: lazy(() => import("screens/InvoiceList/InvoicesTab")),
    isPublic: false,
    path: "/invoices",
    translationTab: "Recibos",
  },
  {
    component: lazy(
      () => import("screens/ElectronicVoucherList/ElectronicVoucherList"),
    ),
    isPublic: false,
    path: "/electronic-vouchers/member/:id",
    translationTab: "Facturas/soci@",
  },
  {
    component: lazy(() => import("screens/ElectronicVoucher")),
    isPublic: false,
    path: "/electronic-vouchers/:id",
    translationTab: "Facturas/detalle",
  },
  {
    component: lazy(() => import("screens/Payments/UserPayment")),
    isPublic: false,
    path: "/user-payments",
    translationTab: "Pagos manuales",
  },
  {
    component: lazy(() => import("screens/BulkInvoice")),
    isPublic: false,
    path: "/bulk-invoices",
    translationTab: "Bulk Invoice",
  },
  {
    component: lazy(() => import("screens/BulkInvoiceV2")),
    isPublic: false,
    path: "/bulk-invoices-v2",
    translationTab: "Bulk Invoice V2",
  },
  {
    component: lazy(
      () => import("screens/ElectronicVoucherList/ElectronicVoucherList"),
    ),
    isPublic: false,
    path: "/electronic-vouchers",
    translationTab: "Facturas",
  },
  {
    component: lazy(
      () => import("screens/ComplianceAlert/ComplianceAlertDetails"),
    ),
    isPublic: false,
    path: "/compliance-alerts/details/:id",
    translationTab: "Alertas/detalle",
  },
  {
    component: lazy(() => import("screens/ComplianceAlert")),
    isPublic: false,
    path: "/compliance-alerts/:id",
    translationTab: "Alertas/detalle",
  },
  {
    component: lazy(() => import("screens/ComplianceAlertList")),
    isPublic: false,
    path: "/compliance-alerts",
    translationTab: "Alertas",
  },
  {
    component: lazy(() => import("screens/AdminTasks")),
    isPublic: false,
    path: "/admin-tasks",
    translationTab: "Admin Tasks",
  },
  {
    component: lazy(() => import("screens/Quote")),
    isPublic: false,
    path: "/quote",
    translationTab: "Cotizador",
  },
  {
    component: lazy(() => import("screens/QuoteB2B")),
    isPublic: false,
    path: "/quote-b2b",
    translationTab: "Cotizador Tiny",
  },
  {
    component: lazy(() => import("screens/QuoteB2BCollective")),
    isPublic: false,
    path: "/quote-b2b-collective",
    translationTab: "Cotizador B2B Colectivo",
  },
  {
    component: lazy(() => import("screens/SalesSubscriptionForm")),
    isPublic: false,
    path: "/sales-subscription-form",
    translationTab: "Subscripción",
  },
  {
    component: lazy(() => import("screens/EditInternalUser")),
    isPublic: false,
    path: "/edit-internal-user/:id",
    groups: [GroupName.SuperAdmin],
    translationTab: "Permisos/Editar usuario interno",
  },
  {
    component: lazy(() => import("screens/CreateInternalUser")),
    isPublic: false,
    path: "/create-internal-user",
    groups: [GroupName.SuperAdmin],
    translationTab: "Permisos/Crear usuario interno",
  },
  {
    component: lazy(() => import("screens/EditIncentiveCode")),
    isPublic: false,
    path: "/edit-incentive-code/:id",
    translationTab: "Permisos/Editar usuario interno",
  },
  {
    component: lazy(() => import("screens/CreateIncentiveCode")),
    isPublic: false,
    path: "/create-incentive-code",
    translationTab: "Permisos/Crear usuario interno",
  },
  {
    component: lazy(() => import("screens/Doctor")),
    isPublic: false,
    path: "/doctors/:id",
    translationTab: "Doctores/detalle",
  },
  {
    component: lazy(() => import("screens/DoctorList")),
    isPublic: false,
    path: "/doctors",
    translationTab: "Doctores",
  },
  {
    component: lazy(() => import("screens/DoctorLead")),
    isPublic: false,
    path: "/doctor-leads/:id",
    translationTab: "Doctor Leads/detalle",
  },
  {
    component: lazy(() => import("screens/DoctorLeadsList")),
    isPublic: false,
    path: "/doctor-leads",
    translationTab: "Doctor Leads",
  },
  {
    component: lazy(() => import("screens/CreateScheduledAppointment")),
    isPublic: false,
    path: "/doctor-appointments/create-scheduled-appointment/:id",
    translationTab: "Registro de citas/Crear cita",
  },
  {
    component: lazy(() => import("screens/CreateScheduledAppointment")),
    isPublic: false,
    path: "/doctor-appointments/create-scheduled-appointment/",
    translationTab: "Registro de citas/Crear cita",
  },
  {
    component: lazy(() => import("screens/CreateRequestedAppointment")),
    isPublic: false,
    path: "/doctor-appointments/create-appointment-request/:id",
    translationTab: "Registro de citas/Solicitar cita",
  },
  {
    component: lazy(() => import("screens/CreateRequestedAppointment")),
    isPublic: false,
    path: "/doctor-appointments/create-appointment-request/",
    translationTab: "Registro de citas/Solicitar cita",
  },
  {
    component: lazy(() => import("screens/CreateExternalAppointment")),
    isPublic: false,
    path: "/doctor-appointments/create-external-appointment/",
    translationTab: "Registro de citas externas/Crear cita Externa",
  },
  {
    component: lazy(() => import("screens/EditAppointment")),
    isPublic: false,
    path: "/doctor-appointments/edit/:id",
    translationTab: "Registro de citas/actualizar",
  },
  {
    component: lazy(() => import("screens/EditExternalAppointment")),
    isPublic: false,
    path: "/doctor-appointments/external-appointment-edit/:id",
    translationTab: "Registro de citas externas/actualizar",
  },
  {
    component: lazy(() => import("screens/DoctorAppointments")),
    isPublic: false,
    path: "/doctor-appointments",
    translationTab: "Registro de citas",
  },
  {
    component: lazy(() => import("screens/UpdateInPersonConsult")),
    isPublic: false,
    path: "/update-in-person-consult/:id",
    translationTab: "Editar consulta presencial",
  },
  {
    component: lazy(() => import("screens/CreateBusinessStakeholder")),
    isPublic: false,
    path: "/businesses/:businessId/stakeholders/create",
    translationTab: "Stakeholders/crear",
  },
  {
    component: lazy(() => import("screens/UpdateBusinessStakeholder")),
    isPublic: false,
    path: "/businesses/:businessId/stakeholders/:stakeholderId/edit",
    translationTab: "Stakeholders/actualizar",
  },
  {
    component: lazy(() => import("screens/BusinessStakeholder")),
    isPublic: false,
    path: "/businesses/:businessId/stakeholders/:stakeholderId",
    translationTab: "Stakeholder",
  },
  {
    component: lazy(() => import("screens/CreateBusinessAdmin")),
    isPublic: false,
    path: "/businesses/:businessId/admins/create",
    translationTab: "Admins/crear",
  },
  {
    component: lazy(() => import("screens/UpdateBusinessAdmin")),
    isPublic: false,
    path: "/businesses/:businessId/admins/:adminId/edit",
    translationTab: "Admin/actualizar",
  },
  {
    component: lazy(() => import("screens/BusinessAdmin")),
    isPublic: false,
    path: "/businesses/:businessId/admins/:adminId",
    translationTab: "Admin",
  },
  {
    component: lazy(() => import("screens/UpdateOrCreateBusinessBillingInfo")),
    isPublic: false,
    path: "/businesses/edit/billing-info/:id",
    translationTab: "Editar Billing Info",
  },
  {
    component: lazy(
      () => import("screens/UpdateOrCreateBusinessGeneralConfigInfo"),
    ),
    isPublic: false,
    path: "/businesses/edit/general-config/:id",
    translationTab: "Editar Configuración General",
  },
  {
    component: lazy(
      () => import("screens/UpdateOrCreateBusinessSuperAdminInfo"),
    ),
    isPublic: false,
    path: "/businesses/edit/super-admin/:id",
    translationTab: "Editar Super Admin",
  },
  {
    component: lazy(
      () => import("screens/UpdateOrCreateBusinessPayoutClabeInfo"),
    ),
    isPublic: false,
    path: "/businesses/edit/payout-clabe/:id",
    translationTab: "Editar Información Bancaria",
  },
  {
    component: lazy(() => import("screens/UpdateBusinessBillingDay")),
    isPublic: false,
    path: "/businesses/edit/billing-day/:id",
    translationTab: "Editar Día de Corte",
  },
  {
    component: lazy(
      () => import("screens/BusinessIncentiveCode/BusinessIncentiveCode"),
    ),
    isPublic: false,
    path: "/businesses/:businessId/incentive-code/:incentiveCodeId",
    translationTab: "Código de descuento",
  },
  {
    component: lazy(() => import("screens/UpdateBusiness")),
    isPublic: false,
    path: "/businesses/edit/:id",
    translationTab: "Editar Empresa",
  },
  {
    component: lazy(() => import("screens/CreateBusinessDraft")),
    isPublic: false,
    path: "/businesses/create-draft",
    translationTab: "Crear Empresa Borrador",
  },
  {
    component: lazy(() => import("screens/UpdateBusinessGroupConfig")),
    path: "/businesses/:businessId/groups-config/update/:id",
    isPublic: false,
    translationTab: "Empresas/config-grupos/actualizar",
  },
  {
    component: lazy(() => import("screens/BusinessMaternityAmendment")),
    path: "/businesses/:businessId/amendment/maternity/:action",
    isPublic: false,
    translationTab: "Editar empresa",
  },
  {
    component: lazy(() => import("screens/BusinessGroupConfigAmendment")),
    path: "/businesses/:businessId/amendment/config-group/:id",
    isPublic: false,
    translationTab: "Editar empresa",
  },
  {
    component: lazy(() => import("screens/AmendmentChangeGroupUpdate")),
    path: "/businesses/:businessId/amendment/change-group",
    isPublic: false,
    translationTab: "Editar empresa",
  },
  {
    component: lazy(() => import("screens/ReissueMaternityUpdate")),
    path: "/businesses/:businessId/reissue/maternity/:action",
    isPublic: false,
    translationTab: "Editar empresa",
  },
  {
    component: lazy(() => import("screens/ReissueConfigGroupUpdate")),
    path: "/businesses/:businessId/reissue/config-group",
    isPublic: false,
    translationTab: "Editar empresa",
  },
  {
    component: lazy(() => import("screens/ReissueChangeGroupUpdate")),
    path: "/businesses/:businessId/reissue/change-group",
    isPublic: false,
    translationTab: "Editar empresa",
  },
  {
    component: lazy(() => import("screens/CreateBusinessGroupConfig")),
    path: "/businesses/:businessId/groups-config/create",
    isPublic: false,
    translationTab: "Empresas/config-grupos/crear",
  },
  {
    component: lazy(() => import("screens/BusinessGroupsConfig")),
    path: "/businesses/:businessId/groups-config/:id",
    isPublic: false,
    translationTab: "Empresa Config",
  },
  {
    component: lazy(() => import("screens/CreateBusinessEmployee")),
    isPublic: false,
    path: "/businesses/:businessId/employees/create",
    translationTab: "Crear Colaborador",
  },
  {
    component: lazy(() => import("screens/CreateBusinessRelative")),
    isPublic: false,
    path: "/businesses/:businessId/employees/create-relative",
    translationTab: "Crear Familiar",
  },
  {
    component: lazy(() => import("screens/UpdateBusinessEmployee")),
    isPublic: false,
    path: "/businesses/:businessId/employees/:employeeId/edit",
    translationTab: "Editar Colaborador",
  },
  {
    component: lazy(() => import("screens/UpdateBusinessRelative")),
    isPublic: false,
    path: "/businesses/:businessId/employees/:employeeId/update-relative",
    translationTab: "Editar Familiar",
  },
  {
    component: lazy(() => import("screens/BusinessEmployee")),
    isPublic: false,
    path: "/businesses/:businessId/employees/:employeeId",
    translationTab: "Colaborador",
  },
  {
    component: lazy(() => import("screens/CancelBusinessCollectiveHealthPlan")),
    isPublic: false,
    path: "/businesses/:id/cancel-collective-health-plan",
    translationTab: "Colaborador",
  },
  {
    component: lazy(() => import("screens/Business")),
    isPublic: false,
    path: "/businesses/:id/:tab",
    translationTab: "Empresas/detalle",
  },
  {
    component: lazy(() => import("screens/Business")),
    isPublic: false,
    path: "/businesses/:id",
    translationTab: "Empresas/detalle",
  },
  {
    component: lazy(() => import("screens/BusinessList")),
    isPublic: false,
    path: "/businesses",
    translationTab: "Empresas",
  },
  {
    component: lazy(() => import("screens/ExclusionUpdate")),
    isPublic: false,
    path: "/exclusions-catalog/exclusion/:id",
    translationTab: "Exclusión",
  },
  {
    component: lazy(() => import("screens/ExclusionCreate")),
    isPublic: false,
    path: "/exclusions-catalog/exclusion-create",
    translationTab: "Crear Exclusión",
  },
  {
    component: lazy(() => import("screens/ExclusionsCatalog")),
    isPublic: true,
    path: "/exclusions-catalog",
    translationTab: "Catálogo de exclusiones",
  },
  {
    component: lazy(() => import("screens/CptUpdate")),
    isPublic: false,
    path: "/cpt-catalog/cpt/:id",
    translationTab: "Procedimiento",
  },
  {
    component: lazy(() => import("screens/CptCreate")),
    isPublic: false,
    path: "/cpt-catalog/cpt-create",
    translationTab: "Crear procedimiento",
  },
  {
    component: lazy(() => import("screens/CptCatalog")),
    isPublic: true,
    path: "/cpt-catalog",
    translationTab: "Catálogo de procedimientos",
  },
  {
    component: lazy(() => import("screens/CieCreate")),
    isPublic: false,
    path: "/cie-catalog/cie-create",
    translationTab: "Crear diagnóstico",
  },
  {
    component: lazy(() => import("screens/CieUpdate")),
    isPublic: false,
    path: "/cie-catalog/cie/:id",
    translationTab: "diagnóstico",
  },
  {
    component: lazy(() => import("screens/CieCatalog")),
    isPublic: true,
    path: "/cie-catalog",
    translationTab: "Catálogo de procedimientos",
  },
  {
    component: lazy(() => import("screens/NotAuthorized")),
    path: "/401",
    isPublic: true,
  },
  {
    component: lazy(() => import("screens/DoctorFriendList")),
    isPublic: false,
    path: "/doctors-friends",
    translationTab: "Doctores amig@s",
  },
  {
    component: lazy(
      () => import("screens/CptPriceReferenceList/CptPriceReferenceTab"),
    ),
    isPublic: false,
    path: "/cptReference",
    translationTab: "Tabuladores",
  },
  {
    component: lazy(() => import("screens/ServiceCoveragePayouts")),
    isPublic: false,
    path: "/service-coverage-payouts",
    translationTab: "Órdenes de pago",
  },
  {
    component: lazy(() => import("screens/ServiceCoveragePayoutDetail")),
    isPublic: false,
    path: "/service-coverage-payout/:id",
    translationTab: "Orden de pago",
  },
  {
    component: lazy(() => import("screens/Map")),
    isPublic: false,
    path: "/map",
    translationTab: "Mapa",
  },
];

export default routes;
