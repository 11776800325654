import routes, { Route } from "./routes";

export interface DrawerSubmenuItem {
  activeWith?: Route[];
  title: string;
  route?: Route;
}

export interface DrawerItem {
  activeWith?: Route[];
  title: string;
  route?: Route;
  items?: DrawerSubmenuItem[];
}

const findRoute = (path: string, overwrites?: Partial<Route>) => {
  const route = routes.find(route => route.path === path);
  if (route) {
    return {
      ...route,
      ...overwrites,
    } as Route;
  }
  throw new Error(`Could not find route "${path}".`);
};

const drawerData: DrawerItem[] = [
  {
    title: "Aplicaciones",
    route: findRoute("/applications"),
    activeWith: [
      findRoute("/applications/:id"),
      findRoute("/applications/item/:id"),
      findRoute("/applications/member/:id"),
    ],
  },
  {
    title: "Socios",
    items: [
      {
        title: "Perfil de socios",
        route: findRoute("/members"),
        activeWith: [findRoute("/members/:id")],
      },
      {
        title: "Validaciones Colectivo",
        route: findRoute("/profiling-data-members"),
      },
    ],
  },
  {
    title: "Empresas",
    route: findRoute("/businesses"),
    activeWith: [
      findRoute("/businesses/:businessId/stakeholders/create"),
      findRoute("/businesses/:businessId/stakeholders/:stakeholderId/edit"),
      findRoute("/businesses/:businessId/stakeholders/:stakeholderId"),
      findRoute("/businesses/:businessId/admins/create"),
      findRoute("/businesses/:businessId/admins/:adminId/edit"),
      findRoute("/businesses/:businessId/admins/:adminId"),
      findRoute("/businesses/:businessId/employees/create"),
      findRoute("/businesses/:businessId/employees/:employeeId/edit"),
      findRoute("/businesses/:businessId/employees/:employeeId"),
      findRoute("/businesses/edit/:id"),
      findRoute("/businesses/:id"),
      findRoute("/businesses/:id/:tab"),
      findRoute("/businesses"),
    ],
  },
  {
    title: "SofiaMed",
    items: [
      {
        title: "Doctores",
        route: findRoute("/doctors"),
        activeWith: [findRoute("/doctors/:id")],
      },
      {
        title: "Leads",
        route: findRoute("/doctor-leads"),
        activeWith: [findRoute("/doctor-leads/:id")],
      },
      {
        title: "Doctores Amig@",
        route: findRoute("/doctors-friends"),
        activeWith: [findRoute("/medical-specializations/create")],
      },
      {
        title: "Especialidades",
        route: findRoute("/medical-specializations"),
        activeWith: [
          findRoute("/medical-specializations/create"),
          findRoute("/medical-specializations/update/:id"),
        ],
      },
      {
        title: "Proveedores",
        route: findRoute("/providers/:tab", { path: "/providers/list" }),
        activeWith: [
          findRoute("/providers/branch-offices/:id"),
          findRoute("/providers/branch-offices/item/:id"),
          findRoute("/providers/:tab"),
          findRoute("/providers/item/:id"),
        ],
      },
      {
        title: "Mapa",
        route: findRoute("/map"),
      },
    ],
  },
  {
    title: "Eventos médicos",
    route: findRoute("/events-v2"),
    activeWith: [findRoute("/events-v2/detail/:id")],
  },
  {
    title: "Servicios médicos",
    items: [
      {
        title: "Registro de Servicios",
        route: findRoute("/services"),
        activeWith: [
          findRoute("/services/:id"),
          findRoute("/services/detail/:id"),
          findRoute("/services/detail/:id/:tab"),
          findRoute("/events/:id"),
        ],
      },
      {
        title: "Completar diagnosis",
        route: findRoute("/incomplete-services"),
      },
      {
        title: "Registro de citas",
        route: findRoute("/doctor-appointments"),
        activeWith: [
          findRoute("/doctor-appointments/create-appointment-request/"),
          findRoute("/doctor-appointments/create-appointment-request/:id"),
          findRoute("/doctor-appointments/create-scheduled-appointment/"),
          findRoute("/doctor-appointments/create-scheduled-appointment/:id"),
          findRoute("/doctor-appointments/edit/:id"),
        ],
      },
      {
        title: "Auditoría de Consultas",
        route: findRoute("/consults-audit"),
      },
      {
        title: "Orden de Farmacia",
        route: findRoute("/pharmacy-orders/:tab", {
          path: "/pharmacy-orders/orders",
        }),
        activeWith: [
          findRoute("/pharmacy-orders/:tab"),
          findRoute("/pharmacy-orders/item/:id"),
        ],
      },
      {
        title: "Catálogo de Exclusiones",
        route: findRoute("/exclusions-catalog"),
        activeWith: [findRoute("/exclusions-catalog/exclusion-create")],
      },
      {
        title: "CPTs",
        route: findRoute("/cpt-catalog"),
        activeWith: [findRoute("/cpt-catalog/cpt-create")],
      },
      {
        title: "CIEs",
        route: findRoute("/cie-catalog"),
        activeWith: [findRoute("/cie-catalog/cie-create")],
      },
      {
        title: "Órdenes de pago",
        route: findRoute("/service-coverage-payouts"),
        activeWith: [findRoute("/service-coverage-payout/:id")],
      },
    ],
  },
  {
    title: "Tabuladores",
    route: findRoute("/cptReference"),
    activeWith: [findRoute("/cptReference")],
  },
  {
    title: "Administración de póliza",
    items: [
      {
        title: "Planes",
        route: findRoute("/healthplans"),
        activeWith: [
          findRoute("/healthplans/:id"),
          findRoute("/healthplans/:id/reissue"),
        ],
      },
      {
        title: "Endosos",
        route: findRoute("/amendments"),
        activeWith: [findRoute("/amendments/:id")],
      },
    ],
  },
  {
    title: "Credenciales",
    route: findRoute("/users"),
    activeWith: [findRoute("/users/:id")],
  },
  {
    title: "Finanzas",
    items: [
      {
        title: "Recibos",
        route: findRoute("/invoices/:tab", { path: "/invoices/members" }),
        activeWith: [
          findRoute("/invoices/:tab"),
          findRoute("/invoices/:tab/:id"),
          findRoute("/invoices/:tab/member/:id"),
        ],
      },
      {
        title: "Pagos manuales",
        route: findRoute("/user-payments"),
      },
      {
        title: "Bulk Invoices",
        route: findRoute("/bulk-invoices"),
        activeWith: [findRoute("/bulk-invoices")],
      },
      {
        title: "Bulk Invoices V2",
        route: findRoute("/bulk-invoices-v2"),
        activeWith: [findRoute("/bulk-invoices-v2")],
      },
      {
        title: "CFDI",
        route: findRoute("/electronic-vouchers"),
        activeWith: [
          findRoute("/electronic-vouchers/:id"),
          findRoute("/electronic-vouchers/member/:id"),
        ],
      },
    ],
  },
  {
    title: "Cumplimiento",
    items: [
      {
        title: "Alertas",
        route: findRoute("/compliance-alerts"),
        activeWith: [
          findRoute("/compliance-alerts/:id"),
          findRoute("/compliance-alerts/details/:id"),
        ],
      },
    ],
  },
  {
    title: "Adquisición",
    items: [
      {
        title: "Subscripción",
        route: findRoute("/sales-subscription-form"),
      },
      {
        title: "Cotizador B2C",
        route: findRoute("/quote"),
      },
      {
        title: "Cotizador Tiny",
        route: findRoute("/quote-b2b"),
      },
      {
        title: "Cotizador B2B Colectivo",
        route: findRoute("/quote-b2b-collective"),
      },
      {
        title: "Zonas de cobertura",
        route: findRoute("/locations"),
        activeWith: [
          findRoute("/locations/create"),
          findRoute("/locations/:id"),
          findRoute("/locations/edit/:id"),
          findRoute("/locations/edit-batch/:ids"),
        ],
      },
      {
        title: "Incentive Codes",
        route: findRoute("/incentive-codes"),
        activeWith: [
          findRoute("/edit-incentive-code/:id"),
          findRoute("/create-incentive-code"),
        ],
      },
    ],
  },
  {
    title: "Usuarios",
    route: findRoute("/permissions"),
    activeWith: [
      findRoute("/edit-internal-user/:id"),
      findRoute("/create-internal-user"),
    ],
  },
];

export default drawerData;
