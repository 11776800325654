export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isNotString(value: unknown) {
  return !isString(value);
}

export function isEmptyString(value: unknown): value is string {
  return isString(value) && value === "";
}

export function isNotEmptyString(value: unknown): value is string {
  return isString(value) && value !== "";
}
