import cache from "config/cache";
import { SCREEN_XL } from "config/style";
import { makeAutoObservable } from "mobx";

class AppLayoutState {
  constructor() {
    makeAutoObservable(this);
  }

  private isMobileMenuVisible = false;

  isMobileMenu = false;

  get isDesktopMenuVisible() {
    return cache.settings.get("desktopMenuIsVisible");
  }

  get isMobileBreakpoint() {
    return window.innerWidth < SCREEN_XL;
  }

  get isMenuVisible() {
    const { isMobileMenu } = this;
    if (isMobileMenu) return this.isMobileMenuVisible;
    return this.isDesktopMenuVisible;
  }

  set isMenuVisible(value: boolean) {
    const { isMobileMenu } = this;
    if (isMobileMenu) {
      this.isMobileMenuVisible = value;
    } else {
      cache.settings.set("desktopMenuIsVisible", value);
    }
  }

  toggleIsMenuVisible = () => {
    const { isMenuVisible } = this;
    this.isMenuVisible = !isMenuVisible;
  };
}

export default AppLayoutState;
