import { APP_MENU_WIDTH } from "config/style";
import AppSider from "library/components/AppSider";
import React from "react";
import { Drawer } from "./AppMenu.css";

interface Props {
  onClose?: () => void;
  onLinkClick?: () => void;
  visible?: boolean;
  wrapInDrawer?: boolean;
}

const AppMenu: React.FC<Props> = ({
  onClose,
  onLinkClick,
  visible,
  wrapInDrawer,
}) => {
  if (wrapInDrawer) {
    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        closable={false}
        width={APP_MENU_WIDTH}
        placement="left"
      >
        <AppSider siderProps={{ collapsed: false }} onLinkClick={onLinkClick} />
      </Drawer>
    );
  }
  return (
    <AppSider
      siderProps={{
        collapsed: !visible,
        onCollapse: onClose,
      }}
      onLinkClick={onLinkClick}
    />
  );
};

export default AppMenu;
