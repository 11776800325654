import Icon from "@ant-design/icons";
import React from "react";
import { IconProps } from "./IconTypes";

const SunSvg = () => {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 120 120">
      <path d="M63.6722 2.50404C62.2822 -0.714946 57.7178 -0.714949 56.3278 2.50404L49.5644 18.1663C48.6088 20.3794 45.9273 21.2507 43.8534 20.022L29.1756 11.3263C26.159 9.53913 22.4663 12.222 23.2338 15.6433L26.9682 32.2897C27.4959 34.6418 25.8386 36.9229 23.4386 37.1479L6.45288 38.7403C2.9619 39.0675 1.55141 43.4086 4.1833 45.7253L16.989 56.9975C18.7985 58.5903 18.7985 61.4097 16.989 63.0025L4.18331 74.2747C1.55141 76.5914 2.96189 80.9325 6.45287 81.2597L23.4386 82.8521C25.8386 83.0771 27.4959 85.3582 26.9682 87.7103L23.2338 104.357C22.4663 107.778 26.159 110.461 29.1756 108.674L43.8534 99.978C45.9273 98.7493 48.6088 99.6206 49.5644 101.834L56.3278 117.496C57.7178 120.715 62.2822 120.715 63.6722 117.496L70.4356 101.834C71.3912 99.6206 74.0727 98.7493 76.1466 99.978L90.8244 108.674C93.841 110.461 97.5337 107.778 96.7662 104.357L93.0318 87.7103C92.5041 85.3582 94.1614 83.0771 96.5614 82.8521L113.547 81.2597C117.038 80.9325 118.449 76.5914 115.817 74.2747L103.011 63.0025C101.202 61.4097 101.202 58.5903 103.011 56.9975L115.817 45.7253C118.449 43.4086 117.038 39.0675 113.547 38.7403L96.5614 37.1479C94.1614 36.9229 92.5041 34.6418 93.0318 32.2897L96.7662 15.6433C97.5337 12.222 93.841 9.53913 90.8244 11.3263L76.1467 20.022C74.0727 21.2507 71.3912 20.3794 70.4356 18.1663L63.6722 2.50404Z" />
    </svg>
  );
};

export const SunIcon = (props: IconProps) => (
  <Icon component={SunSvg} {...props} />
);

export default SunIcon;
