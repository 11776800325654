import * as Sentry from "@sentry/react";
import cache from "config/cache";
import { rmNull } from "library/utils/fields";
import { makeAutoObservable } from "mobx";
import LoadStore from "stores/LoadStore";
import { selfQuery, tokenAuth, tokenRefresh } from "./loginApi";

class LoginState {
  loader = new LoadStore();
  userInfo = cache.userInfo;

  email = "";
  password = "";
  sessionIsKilled = false;
  killedSessionPath?: string;

  openOtp = false;
  hasOtpDevice = false;
  otpFirstToken = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    const { isLoggedIn, token } = cache.userInfo.item;
    return isLoggedIn && Boolean(token);
  }

  get userGroups() {
    const { groups } = cache.userInfo.item;
    return groups;
  }

  get openCreateDeviceModal() {
    return this.openOtp && !this.hasOtpDevice;
  }

  get openConfirmTokenModal() {
    return this.openOtp && this.hasOtpDevice;
  }

  isTokenExpired() {
    const { token } = cache.userInfo.item;
    if (!token) return true;
    const payload = JSON.parse(atob(token.split(".")[1]));
    const exp = payload.exp * 1000;
    return exp <= Date.now();
  }

  killSession() {
    this.sessionIsKilled = true;
    this.killedSessionPath = window.location.pathname;
    this.logout();
  }

  setSessionIsKilled(value: boolean) {
    this.sessionIsKilled = value;
  }

  setKilledSessionPath(value?: string) {
    this.killedSessionPath = value;
  }

  resetOtp() {
    this.openOtp = false;
    this.hasOtpDevice = false;
    this.otpFirstToken = false;
  }

  setOtpFirstToken() {
    this.otpFirstToken = true;
  }

  setHasOtpDevice(value: boolean) {
    this.openOtp = true;
    this.hasOtpDevice = value;
  }

  vefifyOtpDevice() {
    this.openOtp = false;
    this.hasOtpDevice = true;
    this.otpFirstToken = false;
  }

  logout = () => {
    cache.userInfo.reset();
    cache.settings.reset();
    Sentry.setUser(null);
  };

  refresh = async () => {
    await this.loader.load(async () => {
      const { userInfo } = this;
      const oldRefreshToken = userInfo.get("refreshToken");
      if (!oldRefreshToken) return;
      const { refreshToken, token } =
        (await tokenRefresh({
          refreshToken: oldRefreshToken,
        })) || {};
      userInfo.set("refreshToken", refreshToken);
      userInfo.set("token", token);
    });
  };

  async onLoginSuccess(opts: {
    refreshToken?: string | null;
    token?: string | null;
    isVerified?: boolean | null;
  }) {
    const { userInfo } = this;
    userInfo.set("refreshToken", rmNull(opts.refreshToken));
    userInfo.set("token", rmNull(opts.token));

    const self = await selfQuery();

    if (opts.isVerified || self?.multifactorVerificationRequired === false) {
      // 2FA verified
      if (self) {
        userInfo.set("isLoggedIn", true);
        userInfo.set("isDoctor", self.isDoctor);
        userInfo.set("groups", self.groups);
        userInfo.set("id", self.id);
        userInfo.set("email", self.email);
        Sentry.setUser({
          id: self.id,
          email: self.email,
        });
      }
      this.vefifyOtpDevice();
    } else {
      // Not 2FA verified
      this.setHasOtpDevice(self?.hasConfirmedDevice ?? false);
    }
  }

  login = async (opts?: { afterOtpSetup?: boolean }) => {
    await this.loader.load(async () => {
      this.resetOtp();

      const { refreshToken, token, payload } =
        (await tokenAuth({
          email: this.email,
          password: this.password,
        })) || {};

      await this.onLoginSuccess({
        refreshToken,
        token,
        isVerified: payload?.isVerified,
      });

      if (opts?.afterOtpSetup) this.setOtpFirstToken();
    });
  };
}

export default new LoginState();
