import { ReactNode } from "react";

export type RenderIfFnValue<T> = T extends () => unknown
  ? ReturnType<NonNullable<T>>
  : NonNullable<T>;

export interface RenderIfProps<TValue> {
  children?: ReactNode | ((event: RenderIfFnValue<TValue>) => ReactNode);
  fallback?: ReactNode | (() => ReactNode);
  value?: TValue;
}

function RenderIf<TValue>({
  value,
  fallback,
  children,
}: RenderIfProps<TValue>): JSX.Element {
  if (value) {
    const localValue = typeof value === "function" ? value() : value;

    if (localValue) {
      if (typeof children === "function") {
        return <>{children(localValue)}</>;
      }
      return <>{children ? children : value}</>;
    }
  }

  return <>{typeof fallback === "function" ? fallback() : fallback}</>;
}

export default RenderIf;
