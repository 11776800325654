import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { loginState } from "screens/Login";
import ThemeSwitch from "../ThemeSwitch";
import { Button, Header, IconContainer, RightActions } from "./AppToolbar.css";

interface Props {
  isMenuOpen?: boolean;
  onTriggerClick?: () => void;
}

const AppToolbar: React.FC<Props> = ({
  isMenuOpen = false,
  onTriggerClick,
}) => {
  const history = useHistory();
  return (
    <Header>
      <IconContainer onClick={onTriggerClick}>
        {isMenuOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </IconContainer>
      <RightActions>
        <ThemeSwitch />
        <Button
          size="small"
          icon={<LogoutOutlined />}
          onClick={() => {
            loginState.logout();
            history.push("/");
          }}
        >
          Salir
        </Button>
      </RightActions>
    </Header>
  );
};

export default AppToolbar;
