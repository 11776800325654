import { Alert, Input, Modal, Space, Typography } from "antd";
import RenderIf from "library/components/RenderIf";
import { useCallback, useEffect, useState } from "react";
import { useConfirmOtpTokenMutation } from "types/schema";
import loginState from "screens/Login/loginState";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { ApolloError } from "@apollo/client";

const ConfirmToken = observer(() => {
  const history = useHistory();
  const [confirmToken] = useConfirmOtpTokenMutation();
  const [error, setError] = useState<{ message: string }>();
  const [loading, setLoading] = useState(false);
  const [localOpen, setLocalOpen] = useState(
    () => loginState.openConfirmTokenModal,
  );
  const [otpToken, setOtpToken] = useState("");

  const handleOk = useCallback(async () => {
    setLoading(true);
    try {
      const response = await confirmToken({ variables: { otpToken } });
      const load = response.data?.otpVerification;
      await loginState.onLoginSuccess({
        token: load?.token,
        refreshToken: load?.refreshToken,
        // This value is hardcoded because the backend always returns false
        isVerified: true,
      });

      const path = loginState.killedSessionPath;
      if (path) {
        loginState.setKilledSessionPath(undefined);
        history.push(path);
      }
    } catch (error) {
      if (error instanceof ApolloError || error instanceof Error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  }, [confirmToken, history, otpToken]);

  useEffect(() => {
    return autorun(() => {
      setLocalOpen(loginState.openConfirmTokenModal);
    });
  }, []);

  useEffect(() => {
    if (otpToken.length === 6) {
      handleOk();
    }
  }, [handleOk, otpToken]);

  return (
    <Modal
      title={
        loginState.otpFirstToken
          ? "La autenticación en dos pasos está activada"
          : "Autenticación en dos pasos"
      }
      okText="Ingresar"
      cancelText="Regresar"
      open={localOpen}
      onCancel={() => {
        setLocalOpen(false);
        setOtpToken("");
      }}
      onOk={handleOk}
      confirmLoading={loading}
      centered
      width={360}
    >
      <RenderIf value={loginState.otpFirstToken}>
        <Typography.Paragraph>
          Para ingresar a Backstage, por favor genera un nuevo código de
          seguridad en tu app de autenticación y escríbelo. Asegúrate de que sea
          diferente al anterior.
        </Typography.Paragraph>
      </RenderIf>
      <RenderIf value={!loginState.otpFirstToken}>
        <Typography.Paragraph>
          Ingresa el código de verificación de la app de autenticación, como
          Google Authenticator o 1Password, para iniciar sesión.
        </Typography.Paragraph>
      </RenderIf>
      <Space direction="vertical" className="full-width">
        <Input
          value={otpToken}
          onChange={({ target }) => setOtpToken(target.value)}
          className="full-width"
        />
        <RenderIf value={error}>
          {({ message }) => <Alert message={message} type="error" showIcon />}
        </RenderIf>
      </Space>
    </Modal>
  );
});

export default ConfirmToken;
