import LocalStash from "stores/LocalStash";
import { UserType } from "types/schema";
// Remove deprecated items from users localStorage (Dec, 2020)
localStorage.removeItem("LOGIN_DATA");
localStorage.removeItem("LAYOUT_STATE");

enum CacheId {
  Settings = "Settings",
  UserInfo = "UserInfo",
}

export enum Theme {
  Light = "light",
  Dark = "dark",
}

export interface Settings {
  theme?: Theme;
  desktopMenuIsVisible: boolean;
}

export interface UserInfo {
  isDoctor: boolean;
  isLoggedIn: boolean;
  token?: string;
  refreshToken?: string;
  groups?: UserType["groups"];
  id?: string;
  email?: string;
}

export const userInfo = new LocalStash<UserInfo>({
  id: CacheId.UserInfo,
  version: 0,
  initialValue: {
    groups: undefined,
    isDoctor: false,
    isLoggedIn: false,
    id: undefined,
    email: undefined,
  },
});

export const settings = new LocalStash<Settings>({
  id: CacheId.Settings,
  version: 0,
  initialValue: {
    desktopMenuIsVisible: true,
  },
});

const cache = {
  userInfo,
  settings,
};

export default cache;
