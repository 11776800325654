import { UnknownObject } from "types/index";
import {
  MemberBirthCountryCode,
  MemberGender,
  MemberNationalityCountryCode,
  EmrType,
} from "types/schema";

function isEnumType<EnumType extends UnknownObject>(
  value: unknown,
  enm: EnumType,
): value is EnumType {
  return Object.values(enm).includes(value);
}

export function isMemberGender(value: unknown): value is MemberGender {
  return isEnumType<typeof MemberGender>(value, MemberGender);
}

export function isMemberBirthCountry(
  value: unknown,
): value is MemberBirthCountryCode {
  return isEnumType<typeof MemberBirthCountryCode>(
    value,
    MemberBirthCountryCode,
  );
}

export function isMemberNationalityCountry(
  value: unknown,
): value is MemberNationalityCountryCode {
  return isEnumType<typeof MemberNationalityCountryCode>(
    value,
    MemberNationalityCountryCode,
  );
}

export function isEmrType(value: unknown): value is EmrType {
  return isEnumType<typeof EmrType>(value, EmrType);
}
