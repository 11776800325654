import clone from "clone";
import drawerData, { DrawerItem, DrawerSubmenuItem } from "config/drawer";
import { Route } from "config/routes";
import { useEffect, useState } from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";

interface SubMenuItem extends DrawerSubmenuItem {
  key: string;
}

interface MenuItem extends DrawerItem {
  type: "item" | "submenu";
  key: string;
  items?: SubMenuItem[];
}

interface MenuKeys {
  selectedKey?: string;
  openKey?: string;
}

export const menuItems = drawerData.map((config, idx) => {
  const item: MenuItem = {
    ...clone(config),
    key: `${idx}`,
    type: config.items ? "submenu" : "item",
    items: config.items?.map((subMenuItem, subMenuItemIndex) => ({
      ...subMenuItem,
      key: `${idx}-${subMenuItemIndex}`,
    })),
  };
  return item;
});

const matchLocation = (location: string, route?: Route) => {
  if (route) {
    const match = matchPath(location, route.path);
    return Boolean(match);
  }
  return false;
};

const matchFilter = (location: string) => {
  return (match: boolean, route: Route) => {
    if (match) return true;
    return matchLocation(location, route);
  };
};

const getMenuKeys = (pathname: string) =>
  menuItems.reduce<MenuKeys>((output, item) => {
    if (output?.selectedKey) return output;

    // # First level items
    // Route match
    if (matchLocation(pathname, item.route)) {
      output.selectedKey = item.key;
      return output;
    }
    // activeWith match
    const match = item.activeWith?.reduce(matchFilter(pathname), false);
    if (match) {
      output.selectedKey = item.key;
      return output;
    }

    // # items
    if (item.items) {
      for (const subItem of item.items) {
        // Route match
        if (matchLocation(pathname, subItem.route)) {
          output.openKey = item.key;
          output.selectedKey = subItem.key;
          return output;
        }
        // ActiveWith match
        const match = subItem.activeWith?.reduce(matchFilter(pathname), false);
        if (match) {
          output.openKey = item.key;
          output.selectedKey = subItem.key;
          return output;
        }
      }
    }
    return output;
  }, {});

const useMenuKeys = () => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>();
  const [openKey, setOpenKey] = useState<string>();

  useEffect(() => {
    const { pathname } = location;
    const key = getMenuKeys(pathname);
    setSelectedKey(key.selectedKey);
    setOpenKey(key.openKey);
  }, [location]);

  return {
    openKey,
    selectedKey,
    setOpenKey,
    setSelectedKey,
  };
};

export default useMenuKeys;
