export const themes = {
  get prefersDark() {
    return matchMedia("(prefers-color-scheme: dark)").matches;
  },
  get defaultTheme() {
    return this.prefersDark ? "dark" : "light";
  },
  insertionPoint: document.getElementById("theme-switcher-insertion-point"),
  list:
    import.meta.env.VITE_APP_ENV === "development"
      ? {
          light: `${import.meta.env.BASE_URL}themes/theme.mint.css`,
          dark: `${import.meta.env.BASE_URL}themes/theme.mint-dark.css`,
        }
      : {
          light: `${import.meta.env.BASE_URL}themes/theme.default.css`,
          dark: `${import.meta.env.BASE_URL}themes/theme.default-dark.css`,
        },
  favicon:
    import.meta.env.VITE_APP_ENV === "development"
      ? `${import.meta.env.BASE_URL}favicons/favicon-dev.ico`
      : `${import.meta.env.BASE_URL}favicons/favicon.ico`,
};

export default themes;
