import translations from "res/translations";
import moment from "moment";

export const FORMAT_YYMMDD = "YYMMDD";

export const isDate = (value: string) => {
  const timestamp = Date.parse(value);
  return !isNaN(timestamp);
};

const validateDate = (value: string, func: () => string) => {
  if (isDate(value)) {
    return func();
  }
  console.warn(`Value "${value}" is not a valid date.`);
  return "";
};

export const getFormattedDate = (value: string) => {
  return validateDate(value, () => {
    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();
    const formattedDay = `${day}`.padStart(2, "0");
    const formattedMonth = translations.months[month];
    return `${formattedDay}/${formattedMonth}/${year}`;
  });
};

export const getFormattedDateNonUtc = (value: string) => {
  return validateDate(value, () => {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const formattedDay = `${day}`.padStart(2, "0");
    const formattedMonth = translations.months[month];
    return `${formattedDay}/${formattedMonth}/${year}`;
  });
};

export const getFormattedTime = (value: string) => {
  return validateDate(value, () => {
    const date = new Date(value);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = `${hours}`.padStart(2, "0");
    const formattedMinutes = `${minutes}`.padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  });
};

export const getFormattedDateAndTime = (value: string) => {
  return validateDate(value, () => {
    const formattedDate = getFormattedDate(value);
    const formattedTime = getFormattedTime(value);
    return `${formattedDate}, ${formattedTime}`;
  });
};

export const getFormattedDateAndTimeNonUtc = (value: string) => {
  return validateDate(value, () => {
    const formattedDate = getFormattedDateNonUtc(value);
    const formattedTime = getFormattedTime(value);
    return `${formattedDate}, ${formattedTime}`;
  });
};

/**
 * Disables DatePickers dates greater than now and less than 90 years from now
 */
export const memberAgeFilter = (value: moment.Moment) => {
  const now = moment();
  return value && (value > now || value < now.subtract(90, "years"));
};

export const getCurpDate = (
  curp: string,
): [value: moment.Moment, dateString: string] => {
  const dateText = curp.slice(4, 10);
  const value = moment(dateText, FORMAT_YYMMDD, true);
  return [value, value.format(FORMAT_YYMMDD)];
};

export const getRfcDate = (
  rfc: string,
): [value: moment.Moment, dateString: string] => {
  const dateText = rfc.slice(4, 10);
  const value = moment(dateText, FORMAT_YYMMDD, true);
  return [value, value.format(FORMAT_YYMMDD)];
};

export const isValidThroughNDaysLeft = (
  dateString?: string | null,
  days = 30,
): boolean => {
  if (!dateString) return false;
  const validThroughDate = new Date(dateString);
  const currentDate = new Date(); // Get current date
  const daysBeforeValidThrough = new Date(validThroughDate);
  daysBeforeValidThrough.setDate(validThroughDate.getDate() - days); // Get date "days" before the valid through date

  // Check if the current date is after or equal to "days" before the valid through date and before or equal to the valid through date
  return (
    currentDate >= daysBeforeValidThrough && currentDate <= validThroughDate
  );
};

export const getMonthsFromDateString = (date: string) => {
  const formattedDate = moment.utc(date).format("DD/MM/YYYY");
  const parsedDate = moment.utc(formattedDate, "DD/MM/YYYY");
  const today = moment.utc();
  const monthsDiff = today.diff(parsedDate, "months");
  return `${monthsDiff} ${monthsDiff === 1 ? "mes" : "meses"}`;
};
