import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { onError } from "@apollo/link-error";
import { SentryLink } from "apollo-link-sentry";
import { loginState } from "screens/Login";
import fragments from "types/schema/fragments";

export const httpLink = createHttpLink({
  uri: import.meta.env.VITE_APP_API_HOST,
  credentials: "same-origin",
});

const LOGOUT_ERROR_MESSAGES = [
  "Signature has expired",
  "Unrecognized session!",
  "Error decoding signature",
];

/**
 * @see {@link https://bit.ly/2A6PO4S} - Catch 401 errors
 */
export const errorLink = onError(({ networkError, graphQLErrors }) => {
  const notAuthorized =
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401;

  const logoutErrorFound = graphQLErrors
    ?.map(({ message }) => LOGOUT_ERROR_MESSAGES.includes(message))
    .includes(true);

  if (notAuthorized || logoutErrorFound) {
    loginState.killSession();
  }
});

export const authLink = setContext(async (ctx, { headers }) => {
  const token = loginState.userInfo.get("token");
  let authorization = "";

  if (token) {
    try {
      authorization = `JWT ${token}`;

      if (loginState.isTokenExpired() && ctx.operationName !== "refreshToken") {
        console.log(
          `[GRAPHQL] Attempting to make request ${ctx.operationName}, but the token is expired. Refreshing...`,
        );

        await loginState.refresh();
        authorization = `JWT ${loginState.userInfo.get("token")}`;
      }
    } catch (_err) {
      console.log(`[GRAPHQL] Error refreshing token...`);
      // If the refresh attempt fails for whatever reason, allow the request to
      // go through with the expired token. The `errorLink` will handle the rest
    }
  }

  return {
    headers: {
      ...headers,
      authorization,
    },
  };
});

export default new ApolloClient({
  link: ApolloLink.from([
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeError: true,
        // For security purposes, don't log the fetch results to avoid
        // leaking personal data
        includeFetchResult: false,
        // By default, we want to capture operation variables for debugging errors. If the operation
        // contains a "password" (eg: a login or reset password mutation), hide the variables.
        transform: breadcrumb => {
          const variables = breadcrumb.data.variables;
          if (variables && "password" in variables) {
            delete breadcrumb.data.variables;
          }
          return breadcrumb;
        },
      },
    }),
    authLink,
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    possibleTypes: fragments.possibleTypes,
    typePolicies: {
      UserType: {
        fields: {
          member: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          groups: {
            merge(existing = [], incoming: string[]) {
              return [...incoming];
            },
          },
        },
      },
    },
  }),

  /**
   * ! All default options should be defined
   * @see {@link https://bit.ly/3escZIg} Default options issue
   */
  defaultOptions: {
    watchQuery: { fetchPolicy: "cache-and-network", errorPolicy: "none" },
    query: { fetchPolicy: "network-only", errorPolicy: "none" },
    mutate: { errorPolicy: "none" },
  },
});
