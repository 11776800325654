import { action, computed, makeObservable, observable, toJS } from "mobx";

const getItem = <T>(id: string) => {
  const item = localStorage.getItem(id);
  return item ? (JSON.parse(item) as T) : undefined;
};

const setItem = <T>(id: string, value: T) => {
  localStorage.setItem(id, JSON.stringify(value));
};

export interface LocalStashOptions<T> {
  id: string;
  initialValue: T;
  version?: number;
}

export class LocalStash<T> {
  private id: string;
  private versionId: string;
  private initialValue: T;

  currentItem: T;

  get item() {
    const { currentItem } = this;
    return Object.freeze({ ...currentItem });
  }

  set = <K extends keyof T>(key: K, value: T[K]) => {
    const { id } = this;
    const currentValue = toJS(this.currentItem);
    currentValue[key] = value;
    // Update local storage
    setItem<T>(id, currentValue);
    // Update store
    this.currentItem = currentValue;
  };

  get<K extends keyof T>(key: K): T[K] {
    return this.currentItem[key];
  }

  reset() {
    const { id, initialValue } = this;
    // Reset local storage
    setItem(id, initialValue);
    // Reset store
    this.currentItem = initialValue;
  }

  constructor({ id, initialValue, version = 0 }: LocalStashOptions<T>) {
    this.id = `__Backstage__${id}__`;
    this.versionId = `__Backstage__${id}__version__`;
    this.initialValue = initialValue;

    const currentVersion = getItem<number>(this.versionId);
    const currentItem = getItem<T>(this.id);

    if (currentVersion !== version) {
      setItem<number>(this.versionId, version);
    }

    if (currentVersion !== version || currentItem === undefined) {
      setItem(this.id, initialValue);
      this.currentItem = initialValue;
    } else {
      this.currentItem = currentItem;
    }

    makeObservable(this, {
      currentItem: observable,
      item: computed,
      set: action,
      reset: action,
    });
  }
}

export default LocalStash;
