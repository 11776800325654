import { Layout as AntLayout } from "antd";
import { APP_MENU_WIDTH } from "config/style";
import styled from "styled-components";

export const Layout = styled(AntLayout)`
  min-height: 100vh;
`;

export const ContentLayout = styled(AntLayout)`
  &:not(.is-mobile-menu) {
    transition: margin 0.2s;
    &.is-menu-open {
      margin-left: ${APP_MENU_WIDTH}px;
    }
  }
` as typeof AntLayout;

export const Content = styled(AntLayout.Content)`
  margin: 16px;
` as typeof AntLayout.Content;

export const Loader = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`;
