import { ApolloProvider } from "@apollo/client";
import { notification } from "antd";
import client from "config/client";
import RoutesHandler from "library/components/RoutesHandler";
import useEventListener from "library/hooks/useEventListener";
import { observer } from "mobx-react";
import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { loginState } from "screens/Login";

export const history = createBrowserHistory();

const App: React.FunctionComponent = observer(() => {
  useEventListener("offline", () => {
    notification.error({
      message: "No hay conexión a internet",
      duration: 0,
      key: "offline-notification",
    });
  });

  useEventListener("online", () => {
    notification.close("offline-notification");
    notification.success({
      message: "Conectado a internet",
    });
  });

  return (
    <Router history={history}>
      <ApolloProvider client={client}>
        {<RoutesHandler loginState={loginState} />}
      </ApolloProvider>
    </Router>
  );
});

export default App;
