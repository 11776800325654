import { CreateDevice } from "./CreateDevice/CreateDevice";
import ConfirmToken from "./ConfirmToken/ConfirmToken";
import { observer } from "mobx-react";

const OneTimePassword = observer(() => (
  <>
    <CreateDevice />
    <ConfirmToken />
  </>
));

export default OneTimePassword;
