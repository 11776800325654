import { Layout } from "antd";
import { SCREEN_XL } from "config/style";
import styled from "styled-components";

import { createGlobalStyle } from "styled-components";

// 1. Casting to any because GlobalStyle type is incompatible with react-18
//    types
// 2. Hiding this popup because for some reason it appears when the sider
//    collapses
export const AppSiderSubmenuPopupStyle = createGlobalStyle`
  .app-sider-submenu-popup {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  padding: 16px 24px;
`;

const backgroundColor =
  import.meta.env.VITE_APP_ENV === "production" ? "#5e32d2" : "#33c3be";

export const SiderContainer = styled(Layout.Content)`
  position: fixed;
  left: 0;
  height: 100vh;
  width: auto !important;
  background: ${backgroundColor};
  overflow-y: scroll;

  .ant-layout-sider,
  .ant-menu,
  .ant-menu-submenu,
  .ant-menu-sub,
  .ant-menu-item-group {
    background: ${backgroundColor} !important;
  }

  .ant-layout-sider {
    @media all and (min-width: ${SCREEN_XL}px) {
      position: relative;
      min-height: 100vh;
      overflow: auto;
      height: auto;
    }
  }

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }

  .ant-menu {
    margin: 16px 0;
  }

  .ant-menu-sub,
  .ant-menu-item {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .ant-menu-item-group-title {
    display: none;
  }
` as typeof Layout.Content;
