/*
This pixel had to be implemented this way because:
- it has a dynamic env-var and the current version of Vite does not support
  import.meta.env in the index.html (newer versions do)
- using script with unsafe html rendering does not work as intended, a different
  hack would be needed to make it work
*/

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
function executeSprigPixel() {
  (function (l, e, a, p) {
    if (window.Sprig) return;
    window.Sprig = function () {
      // eslint-disable-next-line prefer-rest-params
      S._queue.push(arguments);
    };
    // eslint-disable-next-line no-var
    var S = window.Sprig;
    S.appId = a;
    S._queue = [];
    window.UserLeap = S;
    a = l.createElement("script");
    a.async = 1;
    a.src = e + "?id=" + S.appId;
    p = l.getElementsByTagName("script")[0];
    p.parentNode.insertBefore(a, p);
  })(
    document,
    "https://cdn.sprig.com/shim.js",
    import.meta.env.VITE_SPRIG_ENVIRONMENT_ID,
  );
}

export default executeSprigPixel;
