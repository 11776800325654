import { Alert, Button, Input, message, Modal, Space, Typography } from "antd";
import RenderIf from "library/components/RenderIf";
import { useCallback, useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import {
  useConfirmOtpDeviceMutation,
  useCreateOtpDeviceMutation,
} from "types/schema";
import loginState from "screens/Login/loginState";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import { CopyOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import styles from "./CreateDevice.module.css";
import { ApolloError } from "@apollo/client";

const CREATE_LOADING_KEY = "create-loading";

interface CreateDeviceInnerProps {
  configUrl: string;
}

const CreateDeviceInner = observer(({ configUrl }: CreateDeviceInnerProps) => {
  const [confirmDevice] = useConfirmOtpDeviceMutation();
  const [localOpen, setLocalOpen] = useState(
    () => loginState.openCreateDeviceModal,
  );
  const [otpToken, setOtpToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string }>();

  useEffect(() => {
    return autorun(() => {
      setLocalOpen(loginState.openCreateDeviceModal);
    });
  }, []);

  const handleOk = useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      const response = await confirmDevice({ variables: { otpToken } });
      if (response.data?.confirmOtpDevice?.ok) {
        await loginState.login({ afterOtpSetup: true });
        setLocalOpen(false);
      } else {
        setError({ message: "Por favor revisa tu código" });
      }
    } catch (err) {
      if (err instanceof ApolloError || err instanceof Error) {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  }, [confirmDevice, otpToken]);

  useEffect(() => {
    if (otpToken.length === 6) {
      handleOk();
    }
  }, [handleOk, otpToken]);

  return (
    <Modal
      title="Activar autenticación en dos pasos"
      open={localOpen}
      confirmLoading={loading || loginState.loader.isLoading}
      okText="Vincular"
      cancelButtonProps={{ disabled: loading || loginState.loader.isLoading }}
      onCancel={() => {
        setLocalOpen(false);
        setOtpToken("");
      }}
      onOk={handleOk}
      centered
    >
      <Typography.Paragraph strong>
        Vincula tu cuenta con la app de autenticación
      </Typography.Paragraph>
      <Typography.Paragraph>
        No te preocupes, esto solo debes hacerlo una vez. Sigue estos pasos:
      </Typography.Paragraph>
      <ol className={styles.stepList}>
        <li className={styles.stepItem}>
          <b>Descarga una app de autenticación</b> (Google Authenticator, Authy,
          Microsoft Authenticator, 1Password, etc.) en tu celular o computadora.
        </li>
        <li className={styles.stepItem}>
          Abre la app y selecciona la opción para{" "}
          <b>agregar una nueva cuenta</b>.
        </li>
        <li className={styles.stepItem}>
          <b>Vincula tu cuenta</b> utilizando una de las siguientes opciones:
          <ul className={styles.innerList}>
            <li>
              Copia y pega el siguiente código:
              <div>
                <CopyToClipboard
                  text={configUrl}
                  onCopy={() => message.success({ content: "Copiado" })}
                >
                  <Button type="link">
                    One-Time Password link <CopyOutlined />
                  </Button>
                </CopyToClipboard>{" "}
              </div>
            </li>
            <li>Escanea el código QR:</li>
          </ul>
        </li>
      </ol>
      <div className={styles.qrWrap}>
        <QRCodeSVG value={configUrl} marginSize={2} size={200} />
      </div>
      <ol className={styles.stepList}>
        <li className={styles.lastStep}>
          Ingresa el código de 6 dígitos que aparece en la app de autenticación.
        </li>
      </ol>
      <Space direction="vertical" className="full-width">
        <Input
          className="full-width"
          value={otpToken}
          onChange={({ target }) => setOtpToken(target.value)}
        />
        <RenderIf value={error || loginState.loader.loadingError}>
          {({ message }) => <Alert type="error" message={message} showIcon />}
        </RenderIf>
      </Space>
    </Modal>
  );
});

export const CreateDevice = observer(() => {
  const [createDevice, { data, loading, error }] = useCreateOtpDeviceMutation();

  useEffect(() => {
    return autorun(() => {
      if (loginState.openCreateDeviceModal) createDevice();
    });
  }, [createDevice]);

  useEffect(() => {
    if (error) {
      Modal.destroyAll();
      Modal.error({
        title: "Error de autenticación",
        content: error?.message,
      });
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      message.destroy(CREATE_LOADING_KEY);
      message.loading({
        key: CREATE_LOADING_KEY,
        content: "Cargando...",
      });
    } else {
      message.destroy(CREATE_LOADING_KEY);
    }
  }, [loading]);

  return (
    <RenderIf value={data?.createOtpDevice?.configUrl}>
      {configUrl => <CreateDeviceInner configUrl={configUrl} />}
    </RenderIf>
  );
});
