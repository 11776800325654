import { Layout, Menu } from "antd";
import { SiderProps } from "antd/lib/layout";
import { APP_MENU_WIDTH } from "config/style";
import Logo from "library/components/Logo";
import { MenuItemType, SubMenuType } from "rc-menu/lib/interface";
import React from "react";
import { Link } from "react-router-dom";
import useMenuKeys, { menuItems } from "../AppMenu/useMenuKeys";
import {
  AppSiderSubmenuPopupStyle,
  LogoContainer,
  SiderContainer,
} from "./AppSider.css";
interface Props {
  siderProps: SiderProps;
  onLinkClick?: () => void;
}

const AppSider: React.FC<Props> = props => {
  const siderProps: SiderProps = {
    collapsedWidth: "0",
    collapsible: true,
    theme: "dark",
    width: APP_MENU_WIDTH,
    ...props.siderProps,
  };
  const menuKeys = useMenuKeys();

  const items = menuItems.map(({ type, title, key, route, items }) => {
    if (type === "item") {
      const menuItem: MenuItemType = {
        key: key || "",
        label: (
          <Link to={route?.path ?? ""} onClick={props.onLinkClick}>
            {title}
          </Link>
        ),
      };
      return menuItem;
    }

    if (!items || !items.length) {
      throw new Error(`Error for submenu "${title}": no items found`);
    }

    const submenuChildren: MenuItemType[] = items.map(
      ({ title, key, route }) => ({
        key,
        label: (
          <Link to={route?.path ?? ""} onClick={props.onLinkClick}>
            {title}
          </Link>
        ),
      }),
    );

    const submenu: SubMenuType = {
      key,
      label: title,
      popupClassName: "app-sider-submenu-popup",
      onTitleClick: () => {
        menuKeys.openKey === key
          ? menuKeys.setOpenKey(undefined)
          : menuKeys.setOpenKey(key);
      },
      children: submenuChildren,
    };
    return submenu;
  });

  return (
    <SiderContainer>
      <AppSiderSubmenuPopupStyle />
      <Layout.Sider {...siderProps}>
        <Link to={"/"}>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Link>
        <Menu
          mode="inline"
          theme="dark"
          openKeys={[menuKeys.openKey || ""]}
          selectedKeys={[menuKeys.selectedKey || ""]}
          items={items}
        />
      </Layout.Sider>
    </SiderContainer>
  );
};

export default AppSider;
