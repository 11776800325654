import { ApolloError } from "@apollo/client";
import { getErrorMsg, getErrorMsgV2 } from "library/utils/error";
import isObject from "lodash/isObject";
import { makeAutoObservable } from "mobx";
import translations from "res/translations";

class LoadStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoading = false;

  loadingMsg = translations.load.loading;

  loadingError?: ApolloError | null;

  isSending = false;

  sendingMsg = translations.load.sending;

  sendingError?: ApolloError | null;

  get hasLoadingError() {
    return isObject(this.loadingError);
  }

  get loadingErrorMsg() {
    const { loadingError } = this;
    if (isObject(loadingError)) {
      return getErrorMsg(loadingError);
    }
    return "";
  }

  get loadingErrorMsgData() {
    const { loadingError } = this;
    if (loadingError) return getErrorMsgV2(loadingError);
    return undefined;
  }

  get loadingErrorMsgV2() {
    const { loadingErrorMsgData } = this;
    if (loadingErrorMsgData) {
      const { title, content } = loadingErrorMsgData;
      return `${title}: ${content}`;
    }
    return undefined;
  }

  get friendlyLoadingErrorMsg() {
    if (this.hasLoadingError) {
      return translations.load.loadingError;
    }
    return "";
  }

  get hasSendingError() {
    return isObject(this.sendingError);
  }

  get sendingErrorMsg() {
    const { sendingError } = this;
    if (isObject(sendingError)) {
      return getErrorMsg(sendingError);
    }
    return "";
  }

  get friendlySendingErrorMsg() {
    if (this.hasSendingError) {
      return translations.load.sendingError;
    }
    return "";
  }

  load = async <T = void>(callback: () => Promise<T>) => {
    this.isLoading = true;
    try {
      await callback();
      this.loadingError = null;
    } catch (error) {
      if (error instanceof ApolloError) this.loadingError = error;
    } finally {
      this.isLoading = false;
    }
  };

  send = async <T = void>(callback: () => Promise<T>) => {
    this.isSending = true;
    try {
      await callback();
      this.sendingError = null;
    } catch (error) {
      if (error instanceof ApolloError) this.sendingError = error;
    } finally {
      this.isSending = false;
    }
  };
}

export default LoadStore;
